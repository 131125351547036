import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { AuthService } from '../../../shared/shared.module';
import { Router, ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    constructor(private authService: AuthService, private router: Router, private activatedRoute: ActivatedRoute) { }

    authenticationInProgress: boolean = false;
    authenticationFailed: boolean = false;

    loginForm = new UntypedFormGroup({
        userName: new UntypedFormControl('', [Validators.required, Validators.pattern('^(([a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4})|(\\d{9}))$')]),
        password: new UntypedFormControl('', Validators.required),
    });

    ngOnInit(): void {
        if (this.authService.isAuthenticated()) {
            const returnUrl = this.activatedRoute.snapshot.queryParams['returnUrl'] || '';
            this.router.navigate([returnUrl]);
        }
    }

    onSubmit() {
        this.authenticationInProgress = true;
        this.authenticationFailed = false;
        const returnUrl = this.activatedRoute.snapshot.queryParams['returnUrl'] || '';
        this.authService.login(this.userName?.value as string, this.password?.value as string)
            .pipe(finalize(() => { this.authenticationInProgress = false; }))
            .subscribe(
            () => {
                this.authenticationFailed = false;
                this.router.navigate([returnUrl]);
            },
            (err: Error) => {
                this.authenticationFailed = true;
                console.error(err);
            }
        );
    }

    get userName() { return this.loginForm.get('userName'); }

    get password() { return this.loginForm.get('password'); }
}
