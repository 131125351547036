import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IKaPerso } from "../models/personal-data.model";
import { IKaUmowy } from "../models/employment.model";

@Injectable({
    providedIn: 'root',
})
export class EmployeeService {

    private readonly personalDataUrl = '/api/v1/employee/personal-data';
    private readonly employmentUrl = '/api/v1/employee/employment';
    private readonly absencesYearsUrl = '/api/v1/employee/absences-years';
    private readonly absencesUrl = '/api/v1/employee/absences?year=';
    private readonly payslipsYearsUrl = '/api/v1/employee/payslips-years';
    private readonly payslipsUrl = '/api/v1/employee/payslips?year=';
    private readonly payslipUrl = '/api/v1/employee/payslip?id=';
    private readonly rmuasYearsUrl = '/api/v1/employee/rmua-years';
    private readonly rmuasUrl = '/api/v1/employee/rmuas?year=';
    private readonly rmuaUrl = '/api/v1/employee/rmua?id=';
    private readonly taxDeclarationsYearsUrl = '/api/v1/employee/tax-declarations-years';
    private readonly taxDeclarationsUrl = '/api/v1/employee/tax-declarations?year=';
    private readonly taxDeclarationUrl = '/api/v1/employee/tax-declaration?id=';
    private readonly absenceRequestsYearsUrl = '/api/v1/employee/absence-requests-years';
    private readonly absenceRequestsUrl = '/api/v1/employee/absence-requests?year=';
    private readonly submitAbsenceRequestUrl = '/api/v1/employee/submit-absence-request';
    private readonly cancelAbsenceRequestUrl = '/api/v1/employee/cancel-absence-request';
    private readonly absenceRequestsPendingApprovalUrl = '/api/v1/employee/absence-requests-pending-approval';
    private readonly absenceRequestsKadryWnioskiTypUrl = '/api/v1/employee/absence-requests-kadry-wnioski-typ?idKaUmowy=';
    private readonly approveAbsenceRequestUrl = '/api/v1/employee/approve-absence-request';
    private readonly rejectAbsenceRequestUrl = '/api/v1/employee/reject-absence-request';

    constructor(private http: HttpClient, private router: Router) { }

    personalData(): Observable<IKaPerso> {
        return this.http.get<IKaPerso>(this.personalDataUrl)
            .pipe(catchError(this.handleError));
    }

    umowy(): Observable<IKaUmowy[]> {
        return this.http.get<IKaUmowy[]>(this.employmentUrl)
            .pipe(catchError(this.handleError));
    }

    absencesYears(): Observable<bigint[]> {
        return this.http.get<bigint[]>(this.absencesYearsUrl)
            .pipe(catchError(this.handleError));
    }

    absences(year: bigint): Observable<any> {
        return this.http.get<any>(this.absencesUrl + year)
            .pipe(catchError(this.handleError));
    }

    payslipsYears(): Observable<bigint[]> {
        return this.http.get<bigint[]>(this.payslipsYearsUrl)
            .pipe(catchError(this.handleError));
    }

    payslips(year: bigint): Observable<any> {
        return this.http.get<any>(this.payslipsUrl + year)
            .pipe(catchError(this.handleError));
    }

    payslip(id: bigint): Observable<any> {
        return this.http.get(this.payslipUrl + id, { responseType: 'blob'})
            .pipe(catchError(this.handleError));
    }

    rmuasYears(): Observable<bigint[]> {
        return this.http.get<bigint[]>(this.rmuasYearsUrl)
            .pipe(catchError(this.handleError));
    }

    rmuas(year: bigint): Observable<any> {
        return this.http.get<any>(this.rmuasUrl + year)
            .pipe(catchError(this.handleError));
    }

    rmua(id: bigint): Observable<any> {
        return this.http.get(this.rmuaUrl + id, { responseType: 'blob' })
            .pipe(catchError(this.handleError));
    }

    taxDeclarationsYears(): Observable<bigint[]> {
        return this.http.get<bigint[]>(this.taxDeclarationsYearsUrl)
            .pipe(catchError(this.handleError));
    }

    taxDeclarations(year: bigint): Observable<any> {
        return this.http.get<any>(this.taxDeclarationsUrl + year)
            .pipe(catchError(this.handleError));
    }

    taxDeclaration(id: bigint): Observable<any> {
        return this.http.get(this.taxDeclarationUrl + id, { responseType: 'blob' })
            .pipe(catchError(this.handleError));
    }

    absenceRequestsYears(): Observable<any> {
        return this.http.get<any>(this.absenceRequestsYearsUrl)
            .pipe(catchError(this.handleError));
    }

    absenceRequests(year: bigint, idKaUmowy: bigint): Observable<any> {
        return this.http.get<any>(this.absenceRequestsUrl + year + '&idKaUmowy=' + idKaUmowy)
            .pipe(catchError(this.handleError));
    }

    submitAbsenceRequest(idKaUmowy: bigint, dateFrom: Date, dateTo: Date, kuide: string, hourly: boolean, hourFrom: string, hourTo: string, uwagi: string, kaPersoZastepstwoId: bigint|null, zastepstwoUwagi: string): Observable<any> {
        return this.http.post<any>(this.submitAbsenceRequestUrl, { idKaUmowy, dateFrom, dateTo, kuide, hourly, hourFrom, hourTo, uwagi, kaPersoZastepstwoId, zastepstwoUwagi })
            .pipe(catchError(this.handleError));
    }

    cancelAbsenceRequest(id: bigint): Observable<boolean> {
        return this.http.post<boolean>(this.cancelAbsenceRequestUrl, { id })
            .pipe(catchError(this.handleError));
    }

    absenceRequestsPendingApproval(): Observable<any> {
        return this.http.get<any>(this.absenceRequestsPendingApprovalUrl)
            .pipe(catchError(this.handleError));
    }

    absenceRequestsKadryWnioskiTyp(idKaUmowy: bigint): Observable<any> {
        return this.http.get<any>(this.absenceRequestsKadryWnioskiTypUrl + idKaUmowy)
            .pipe(catchError(this.handleError));
    }

    approveAbsenceRequest(id: bigint, approvalType: number, uwagi: string | null): Observable<any> {
        return this.http.post<any>(this.approveAbsenceRequestUrl, { id, approvalType, uwagi })
            .pipe(catchError(this.handleError));
    }

    rejectAbsenceRequest(id: bigint, uwagi: string | null): Observable<any> {
        return this.http.post<any>(this.rejectAbsenceRequestUrl, { id, uwagi })
            .pipe(catchError(this.handleError));
    }

    private handleError(error: HttpErrorResponse) {
        if (error.status === 0) {
            console.error('An error occurred:', error.error);
        } else {
            console.error(
                `Backend returned code ${error.status}, body was: `, error.error);
        }
        return throwError(() => new Error('Request error'));
    }
}