import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { MatSidenav, MatDrawerMode } from '@angular/material/sidenav';
import { EventService } from '../../../shared/shared.module';

@Component({
    selector: 'app-sidenav',
    templateUrl: './sidenav.component.html',
    styleUrls: ['./sidenav.component.css']
})
export class SidenavComponent implements OnInit {

    minWidth = 720;
    userPreferenceMode: MatDrawerMode | undefined = undefined;

    constructor(private readonly eventService: EventService) { }

    ngOnInit() {
        this.eventService.toggleSidenav$.subscribe(_ => this.toggleDrawer());
    }

    @ViewChild(MatSidenav)
    sidenav!: MatSidenav;

    toggleDrawer() {
        this.userPreferenceMode = this.sidenav.mode === "over" ? "side" : "over";
        this.sidenav.toggle();
    }

    mode(): MatDrawerMode {
        return this.shouldOpenSidenav ? "side" : "over";
    }

    get shouldOpenSidenav(): boolean {
        return window.innerWidth >= this.minWidth;
    }

    @HostListener('window:resize', ['$event'])
    onResize(event: any) {
        this.adjustSideNav(event.target.innerWidth);
    }
 
    private adjustSideNav(width: any) {
        if (width >= this.minWidth && this.userPreferenceMode === "side") {
            this.sidenav.open();
        }

        if (width < this.minWidth && this.userPreferenceMode === "over"){
            this.sidenav.close();
        }
    }

    itemSelected() {
        if (window.innerWidth < this.minWidth) {
            this.sidenav.close();
        }
    }
}
