<div class="login">
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <mat-card>
            <app-eden-logo></app-eden-logo>
            <mat-card-header>
                <div mat-card-avatar><mat-icon class="account-icon">login</mat-icon></div>
                <mat-card-title>Ustaw nowe hasło</mat-card-title>
                <mat-card-subtitle>Hasło musi składać się z przynajmniej 8 znaków</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <div class="form">
                    <mat-form-field appearance="fill">
                        <mat-label>Hasło</mat-label>
                        <input matInput type="password" required formControlName="password">
                        <mat-icon matSuffix>password</mat-icon>
                        <mat-error *ngIf="password?.errors?.['required']">Hasło nie może być puste</mat-error>
                        <mat-error *ngIf="password?.errors?.['required'] == null && password?.errors?.['noLowercase']">Hasło musi małą literę</mat-error>
                        <mat-error *ngIf="password?.errors?.['required'] == null && password?.errors?.['noLowercase'] == null && password?.errors?.['noUppercase']">Hasło musi wielką literę</mat-error>
                        <mat-error *ngIf="password?.errors?.['required'] == null && password?.errors?.['noLowercase'] == null && password?.errors?.['noUppercase'] == null && password?.errors?.['noDigit']">Hasło musi zawierać cyfrę</mat-error>
                        <mat-error *ngIf="password?.errors?.['required'] == null && password?.errors?.['noLowercase'] == null && password?.errors?.['noUppercase'] == null && password?.errors?.['noDigit'] == null && password?.errors?.['minlength']">Hasło musi składać się z przynajmniej 8 znaków</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>Powtórz hasło</mat-label>
                        <input matInput type="password" required formControlName="password2">
                        <mat-icon matSuffix>password</mat-icon>
                        <mat-error *ngIf="password2?.errors?.['required']">Hasło nie może być puste</mat-error>
                        <mat-error *ngIf="password2?.errors?.['required'] == null && password2?.errors?.['noLowercase']">Hasło musi małą literę</mat-error>
                        <mat-error *ngIf="password2?.errors?.['required'] == null && password2?.errors?.['noLowercase'] == null && password2?.errors?.['noUppercase']">Hasło musi wielką literę</mat-error>
                        <mat-error *ngIf="password2?.errors?.['required'] == null && password2?.errors?.['noLowercase'] == null && password2?.errors?.['noUppercase'] == null && password2?.errors?.['noDigit']">Hasło musi zawierać cyfrę</mat-error>
                        <mat-error *ngIf="password2?.errors?.['required'] == null && password2?.errors?.['noLowercase'] == null && password2?.errors?.['noUppercase'] == null && password2?.errors?.['noDigit'] == null && password2?.errors?.['minlength']">Hasło musi składać się z przynajmniej 8 znaków</mat-error>
                    </mat-form-field>
                    <mat-error *ngIf="loginForm?.errors?.['noMatch']">Hasła muszą być takie same</mat-error>
                    <mat-error *ngIf="setPasswordFailed">W tej chwili nie możemy zrealizować Twojej prośby</mat-error>
                </div>
            </mat-card-content>
            <mat-card-actions>
                <button mat-raised-button color="primary" type="submit" [disabled]="!loginForm.valid || setPasswordInProgress">USTAW</button>
            </mat-card-actions>
            <mat-card-footer>
                <mat-progress-bar mode="indeterminate" *ngIf="setPasswordInProgress"></mat-progress-bar>
            </mat-card-footer>
        </mat-card>
    </form>
</div>


