import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    constructor() { }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (!(request.url.indexOf('refresh-token') > 0)) {
            localStorage.setItem('last-access', Date.now().toString());
        }
        
        const accessToken = localStorage.getItem('access-token');
        const isApiUrl = request.url.startsWith('/api');
        if (accessToken && isApiUrl) {
            request = request.clone({
                setHeaders: { Authorization: `Bearer ${accessToken}` },
            });
        }
        return next.handle(request);
    }
}
