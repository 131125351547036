import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '../../../shared/shared.module';
import { Router, ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'app-confirm-phone-number',
    templateUrl: './confirm-phone-number.component.html',
    styleUrls: ['./confirm-phone-number.component.scss']
})
export class ConfirmPhoneNumberComponent implements OnInit {

    private phoneNumber: string = '';
    private setPassword: boolean = false;

    constructor(private authService: AuthService, private router: Router, private route: ActivatedRoute, public dialog: MatDialog) { }

    invalidToken: boolean = false;
    confirmPhoneNumberFailed: boolean = false;

    confirmPhoneNumberForm = new UntypedFormGroup({
        token: new UntypedFormControl('', [Validators.required])
    });

    ngOnInit() {
        this.phoneNumber = this.route.snapshot.paramMap.get('phoneNumber')!;
        this.route.queryParams.subscribe(params => { this.setPassword = params.setPassword ?? false; });
    }

    onSubmit() {
        this.invalidToken = false;
        this.confirmPhoneNumberFailed = false;
        this.authService.validatePhoneNumberConfirmationToken(this.phoneNumber, this.token?.value)
            .subscribe(
                (tokenValidated) => {
                    if (tokenValidated) {
                        if (this.setPassword) {
                            this.router.navigate(['/main/set-password/' + this.phoneNumber + '/' + this.token?.value]);
                        } else {
                            const dialogRef = this.dialog.open(ConfirmPhoneNumberConfirmationDialog);
                            dialogRef.afterClosed().subscribe(() => { this.router.navigate(['']); });
                        }
                    } else {
                        this.invalidToken = true;
                    }
                },
                (err: Error) => {
                    this.confirmPhoneNumberFailed = true;
                }
            );
    }

    get token() { return this.confirmPhoneNumberForm.get('token'); }
}

@Component({
    selector: 'phone-number-confirmed-confirmation-dialog',
    template: `<h1 mat-dialog-title>Udało się</h1>
                <div mat-dialog-content>Twój numer telefonu został potwierdzony.</div>
                <div mat-dialog-actions>
                    <button mat-raised-button mat-dialog-close>Zamknij</button>
                </div>
`
})
export class ConfirmPhoneNumberConfirmationDialog { }