<mat-card>
    <mat-card-header>
        <div mat-card-avatar><mat-icon class="account-icon">travel_explore</mat-icon></div>
        <mat-card-title>{{data.title}}</mat-card-title>
        <mat-card-subtitle>Dodaj uwagi do wniosku</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <div class="form" [formGroup]="newRequestForm">
            <mat-form-field appearance="fill">
                <mat-label>Uwagi</mat-label>
                <input matInput formControlName="uwagi">
                <mat-icon matSuffix>comment</mat-icon>
                <mat-error *ngIf="newRequestForm.controls.uwagi?.invalid">Uwagi nie mogą być większe niż 50 znaków</mat-error>
            </mat-form-field>
        </div>

    </mat-card-content>
    <mat-card-actions class="buttons">
        <button mat-raised-button color="primary" type="submit" (click)="submitNewRequest()" mat-dialog-close [disabled]="!newRequestForm.valid">Dodaj uwagi</button>
        <button mat-button (click)="onCancel()">Anuluj</button>
    </mat-card-actions>
</mat-card>
