import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { registerLocaleData } from '@angular/common';
import localePl from '@angular/common/locales/pl';
registerLocaleData(localePl);

import { CoreModule } from './modules/core/core.module';
import { EmployeeModule } from './modules/employee/employee.module';
import { MainModule } from './modules/main/main.module';
import { SharedModule, JwtInterceptor } from './modules/shared/shared.module';


@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        BrowserAnimationsModule,

        CoreModule, EmployeeModule, MainModule, SharedModule
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: LOCALE_ID, useValue: "pl-PL" },
        { provide: MAT_DATE_LOCALE, useValue: 'pl-PL' }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
