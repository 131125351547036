<div class="content">
    <div class="card">
        <mat-card>
            <mat-toolbar color="primary">
                <span>Paski płacowe</span>
            </mat-toolbar>
            <mat-toolbar>
                <button mat-button [matMenuTriggerFor]="menu" color="primary">
                    <span>Rok&nbsp;{{selectedYear}}</span>
                    <mat-icon>arrow_drop_down</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button *ngFor="let year of years" (click)="selectionChange(year)" mat-menu-item>
                        <span>{{year}}</span>
                    </button>
                </mat-menu>
            </mat-toolbar>
            <mat-card-content>
                <div class="table">
                    <table mat-table style="width: 100%" *ngIf="payslips" [dataSource]="payslips" class="mat-elevation-z8">
                        <ng-container matColumnDef="rok">
                            <th mat-header-cell mat-header-cell *matHeaderCellDef> Rok </th>
                            <td mat-cell *matCellDef="let element"> {{element.rok}} </td>
                        </ng-container>
                        <ng-container matColumnDef="mc">
                            <th mat-header-cell *matHeaderCellDef> Mc </th>
                            <td mat-cell *matCellDef="let element"> {{element.mc}} </td>
                        </ng-container>
                        <ng-container matColumnDef="nrListy">
                            <th mat-header-cell *matHeaderCellDef> Nr listy </th>
                            <td th mat-cell *matCellDef="let element"> {{element.nrListy }} </td>
                        </ng-container>
                        <ng-container matColumnDef="nazwaListy">
                            <th mat-header-cell *matHeaderCellDef> Nazwa listy </th>
                            <td th mat-cell *matCellDef="let element"> {{element.nazwaListy}} </td>
                        </ng-container>
                        <ng-container matColumnDef="download">
                            <th mat-header-cell *matHeaderCellDef> Pobierz </th>
                            <td mat-cell *matCellDef="let element">
                                <button mat-icon-button color="primary" (click)="downloadPayslip(element.id)">
                                    <mat-icon>download</mat-icon>
                                </button>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="payslipsColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: payslipsColumns;"></tr>
                    </table>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>
