import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AbsencesComponent } from './components/absences/absences.component';
import { AbsenceRequestsComponent } from './components/absence-requests/absence-requests.component';
import { AbsenceRequestsApprovalComponent } from './components/absence-requests-approval/absence-requests-approval.component';
import { EmployeeComponent } from './components/employee/employee.component';
import { EmploymentComponent } from './components/employment/employment.component';
import { PayslipsComponent } from './components/payslips/payslips.component';
import { RmuasComponent } from './components/rmuas/rmuas.component';
import { PersonalDataComponent } from './components/personal-data/personal-data.component';
import { TaxDeclarationsComponent } from './components/tax-declarations/tax-declarations.component';
import { WelcomeComponent } from './components/welcome/welcome.component';

import { AuthGuard } from './../shared/shared.module'

const routes: Routes = [
    { path: '', redirectTo: 'welcome', pathMatch: 'full' },
    {
        path: '', component: EmployeeComponent, children: [
            { path: 'absences', component: AbsencesComponent, canActivate: [AuthGuard] },
            { path: 'absence-requests', component: AbsenceRequestsComponent, canActivate: [AuthGuard] },
            { path: 'absence-requests-approval', component: AbsenceRequestsApprovalComponent, canActivate: [AuthGuard] },
            { path: 'employment', component: EmploymentComponent, canActivate: [AuthGuard] },
            { path: 'payslips', component: PayslipsComponent, canActivate: [AuthGuard] },
            { path: 'rmuas', component: RmuasComponent, canActivate: [AuthGuard] },
            { path: 'personal-data', component: PersonalDataComponent, canActivate: [AuthGuard] },
            { path: 'tax-declarations', component: TaxDeclarationsComponent, canActivate: [AuthGuard] },
            { path: 'welcome', component: WelcomeComponent, canActivate: [AuthGuard] }
        ]
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class EmployeeRoutingModule { }
