import { Component, OnInit, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar'
import { EmployeeService } from '../../services/employee.service';
import { SubmitNewAbsenceRequestDialog } from './submit-new-absence-request-dialog.component';

@Component({
    selector: 'app-absence-requests',
    templateUrl: './absence-requests.component.html',
    styleUrls: ['./absence-requests.component.scss']
})
export class AbsenceRequestsComponent implements OnInit {

    constructor(private employeeService: EmployeeService, public dialog: MatDialog, private snackBar: MatSnackBar) { }

    minWidth = 720;
    isMobile: boolean = false;
    years!: bigint[];
    selectedYear!: bigint;
    kaUmowy!: any;
    selectedKaUmowy!: any;

    absenceRequests!: any;
    kartotekaUrlopowaPozostalo!: any;

    ngOnInit(): void {
        this.adjustWidth(window.screen.width);
        this.employeeService.absenceRequestsYears()
            .subscribe(
                (absenceRequestsYears) => {
                    this.selectedYear = absenceRequestsYears.years[0];
                    this.years = absenceRequestsYears.years;
                    this.kaUmowy = absenceRequestsYears.kaUmowy;
                    this.selectedKaUmowy = absenceRequestsYears.kaUmowy[0];
                    this.selectionChange(this.selectedYear, this.selectedKaUmowy);
                });
    }

    selectionChange(year: bigint, kaUmowa: any): void {
        this.selectedYear = year;
        this.selectedKaUmowy = kaUmowa;
        this.employeeService.absenceRequests(this.selectedYear, this.selectedKaUmowy.id)
            .subscribe((absenceRequests) => {
                this.absenceRequests = absenceRequests.kaWnioski;
                this.kartotekaUrlopowaPozostalo = absenceRequests.kartotekaUrlopowaPozostalo[0];
            });
    }

    newRequest(): void {
        const dialogRef = this.dialog.open(SubmitNewAbsenceRequestDialog, {
            data: { dateFrom: new Date(), dateTo: new Date(), idKaUmowy: this.selectedKaUmowy.id },
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.employeeService.submitAbsenceRequest(this.selectedKaUmowy.id, result.dateFrom, result.dateTo, result.kuide, result.hourly, result.hourFrom, result.hourTo, result.uwagi, result.kaPersoZastepstwoId, result.zastepstwoUwagi).subscribe(
                    (response) => {
                        if (response.result) {
                            this.selectionChange(this.selectedYear, this.selectedKaUmowy);
                            this.snackBar.open('Wniosek został złożony', '', { duration: 3000, verticalPosition: 'top' });
                        } else {
                            this.snackBar.open('Nie udało się złożyć wniosku', response.message, { duration: 3000, verticalPosition: 'top' });
                        }
                    },
                    (err: Error) => {
                        this.snackBar.open('Nie udało się złożyć wniosku', '', { duration: 3000, verticalPosition: 'top' });
                        console.error(err);
                    });
            }
        });
    }

    cancel(id: bigint) {
        this.employeeService.cancelAbsenceRequest(id).subscribe(
            () => {
                this.selectionChange(this.selectedYear, this.selectedKaUmowy);
                this.snackBar.open('Wniosek został anulowany', '', { duration: 3000, verticalPosition: 'top' });
            },
            (err: Error) => {
                this.selectionChange(this.selectedYear, this.selectedKaUmowy);
                this.snackBar.open('Nie udało się anulować wniosku', '', { duration: 3000, verticalPosition: 'top' });
                console.error(err);
            });
    }

    @HostListener('window:resize', ['$event'])
    onResize(event: any) {
        this.adjustWidth(event.target.innerWidth);
    }

    private adjustWidth(width: any) {
        if (width >= this.minWidth) {
            this.isMobile = false;
        }

        if (width < this.minWidth) {
            this.isMobile = true;
        }
    }

    absenceRequestsColumns = ['status', 'typ', 'dOd', 'dDo', 'dniRobocz', 'godzRobocz', 'dataWniosku', 'uwagi', 'zastepca', 'cancel'];
}

