import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar'
import { EmployeeService } from '../../services/employee.service';
import { AddAbsenceRequestApprovalCommentDialog } from './add-absence-request-approval-comment-dialog.component';

@Component({
    selector: 'app-absence-requests-approval',
    templateUrl: './absence-requests-approval.component.html',
    styleUrls: ['./absence-requests-approval.component.scss']
})
export class AbsenceRequestsApprovalComponent implements OnInit {

    constructor(private employeeService: EmployeeService, public dialog: MatDialog, private snackBar: MatSnackBar) { }

    absenceRequests!: any;

    ngOnInit(): void {
        this.selectionChange();
    }

    selectionChange(): void {
        this.employeeService.absenceRequestsPendingApproval()
            .subscribe((absenceRequests) => {
                this.absenceRequests = absenceRequests;
            });
    }

    approve(id: bigint, type: number): void {
        this.employeeService.approveAbsenceRequest(id, type, null).subscribe(
            (response) => {
                this.selectionChange();
                this.snackBar.open(response.result, '', { duration: 3000, verticalPosition: 'top' });
            },
            (err: Error) => {
                this.selectionChange();
                this.snackBar.open('Nie udało się zaakceptować wniosku', '', { duration: 3000, verticalPosition: 'top' });
                console.error(err);
            });
    }

    approveWithComments(id: bigint, type: number): void {
        const dialogRef = this.dialog.open(AddAbsenceRequestApprovalCommentDialog, {
            data: { title: type == 3 ? 'Pełna akceptacja wniosku' : 'Akceptacja wniosku' },
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result.result) {
                this.employeeService.approveAbsenceRequest(id, type, result.uwagi).subscribe(
                    (response) => {
                        this.selectionChange();
                        this.snackBar.open(response.result, '', { duration: 3000, verticalPosition: 'top' });
                    },
                    (err: Error) => {
                        this.selectionChange();
                        this.snackBar.open('Nie udało się zaakceptować wniosku', '', { duration: 3000, verticalPosition: 'top' });
                        console.error(err);
                    });
            }
        });
    }

    reject(id: bigint) {
        this.employeeService.rejectAbsenceRequest(id, null).subscribe(
            (response) => {
                this.selectionChange();
                this.snackBar.open(response.result, '', { duration: 3000, verticalPosition: 'top' });
            },
            (err: Error) => {
                this.selectionChange();
                this.snackBar.open('Nie udało się odrzucić wniosku', '', { duration: 3000, verticalPosition: 'top' });
                console.error(err);
            });
    }

    rejectWithComments(id: bigint) {
        const dialogRef = this.dialog.open(AddAbsenceRequestApprovalCommentDialog, {
            data: { title: 'Odrzucenie wniosku' },
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result.result) {
                this.employeeService.rejectAbsenceRequest(id, result.uwagi).subscribe(
                    (response) => {
                        this.selectionChange();
                        this.snackBar.open(response.result, '', { duration: 3000, verticalPosition: 'top' });
                    },
                    (err: Error) => {
                        this.selectionChange();
                        this.snackBar.open('Nie udało się odrzucić wniosku', '', { duration: 3000, verticalPosition: 'top' });
                        console.error(err);
                    });
            }
        });
    }

    absenceRequestsColumns = ['pracownik', 'typ', 'dOd', 'dDo', 'dniRobocz', 'dataWniosku', 'uwagi', 'zastepca', 'cancel'];
}
