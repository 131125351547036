import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthGuard } from './guards/auth.guard';
import { AuthService } from './services/auth.service';
import { EventService } from './services/event.service';
import { JwtInterceptor } from './interceptors/jwt.interceptor';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
    ],
    providers: [AuthService, EventService]
})
export class SharedModule { }
export { AuthGuard, AuthService, EventService, JwtInterceptor }
