import { Component } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '../../../shared/shared.module';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent {

    constructor(private authService: AuthService, private router: Router, public dialog: MatDialog) { }

    resetPasswordFailed: boolean = false;

    resetPasswordForm = new UntypedFormGroup({
        userName: new UntypedFormControl('', [Validators.required, Validators.pattern('^(([a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4})|(\\d{9}))$')])
    });

    onSubmit() {
        this.resetPasswordFailed = false;
        const userName: string = this.userName?.value;
        this.authService.resetPassword(userName)
            .subscribe(
                () => {
                    const isEmail = userName.includes('@');
                    if (isEmail) {
                        const dialogRef = this.dialog.open(ResetPasswordEmailConfirmationDialog);
                        dialogRef.afterClosed().subscribe(() => { this.router.navigate(['']); });
                    } else {
                        const dialogRef = this.dialog.open(ResetPasswordPhoneNumberConfirmationDialog);
                        dialogRef.afterClosed().subscribe(() => { this.router.navigate(['/main/confirm-phone-number/' + userName], { queryParams: { setPassword: true } }); });
                    }
                },
                (err: Error) => {
                    this.resetPasswordFailed = true;
                    console.error(err);
                }
            );
    }

    get userName() { return this.resetPasswordForm.get('userName'); }
}

@Component({
    selector: 'reset-password-email-confirmation-dialog',
    template: `<h1 mat-dialog-title>Udało się - sprawdź pocztę.</h1>
                <div mat-dialog-content>Jeśli podany email jest poprawny otrzymasz link do ustawienia nowego hasła.</div>
                <div mat-dialog-actions>
                    <button mat-raised-button mat-dialog-close>Zamknij</button>
                </div>
`
})
export class ResetPasswordEmailConfirmationDialog { }

@Component({
    selector: 'reset-password-phone-number-confirmation-dialog',
    template: `<h1 mat-dialog-title>Udało się - sprawdź telefon.</h1>
                <div mat-dialog-content>Jeśli podany telefon jest poprawny otrzymasz kod do ustawienia nowego hasła.</div>
                <div mat-dialog-actions>
                    <button mat-raised-button mat-dialog-close>Zamknij</button>
                </div>
`
})
export class ResetPasswordPhoneNumberConfirmationDialog { }