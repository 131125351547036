<div class="content">
    <div class="card">
        <mat-card>
            <mat-toolbar color="primary">
                <span>Deklaracje PIT</span>
            </mat-toolbar>
            <mat-toolbar>
                <button mat-button [matMenuTriggerFor]="menu" color="primary">
                    <span>Rok&nbsp;{{selectedYear}}</span>
                    <mat-icon>arrow_drop_down</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button *ngFor="let year of years" (click)="selectionChange(year)" mat-menu-item>
                        <span>{{year}}</span>
                    </button>
                </mat-menu>
            </mat-toolbar>
            <mat-card-content>
                <div class="table">
                    <table mat-table style="width: 100%" *ngIf="taxDeclarations" [dataSource]="taxDeclarations" class="mat-elevation-z8">
                        <ng-container matColumnDef="deklaracja">
                            <th mat-header-cell mat-header-cell *matHeaderCellDef> Nazwa </th>
                            <td mat-cell *matCellDef="let element"> {{element.nazwa}} </td>
                        </ng-container>
                        <ng-container matColumnDef="rok">
                            <th mat-header-cell mat-header-cell *matHeaderCellDef> Rok </th>
                            <td mat-cell *matCellDef="let element"> {{element.okresRozliczeniowy1}} </td>
                        </ng-container>
                        <ng-container matColumnDef="wersja">
                            <th mat-header-cell *matHeaderCellDef> Wersja </th>
                            <td mat-cell *matCellDef="let element"> {{element.wersja}} </td>
                        </ng-container>
                        <ng-container matColumnDef="nazwisko">
                            <th mat-header-cell *matHeaderCellDef> Nazwisko </th>
                            <td mat-cell *matCellDef="let element"> {{element.masterField1 }} </td>
                        </ng-container>
                        <ng-container matColumnDef="imie">
                            <th mat-header-cell *matHeaderCellDef> Imie </th>
                            <td mat-cell *matCellDef="let element"> {{element.masterField2 }} </td>
                        </ng-container>
                        <ng-container matColumnDef="refid">
                            <th mat-header-cell *matHeaderCellDef> Nr Ref </th>
                            <td mat-cell *matCellDef="let element"> {{element.refId}} </td>
                        </ng-container>
                        <ng-container matColumnDef="status">
                            <th mat-header-cell *matHeaderCellDef> Status </th>
                            <td mat-cell *matCellDef="let element"> {{element.status}} </td>
                        </ng-container>
                        <ng-container matColumnDef="download">
                            <th mat-header-cell *matHeaderCellDef> Pobierz </th>
                            <td mat-cell *matCellDef="let element">
                                <button mat-icon-button color="primary" (click)="downloadTaxDeclaration(element.id)">
                                    <mat-icon>download</mat-icon>
                                </button>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="taxDeclarationsColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: taxDeclarationsColumns;"></tr>
                    </table>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>
