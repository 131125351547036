import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '../../../shared/shared.module';
import { Router, ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { MyValidators } from '../shared/Validators';

@Component({
    selector: 'app-set-password',
    templateUrl: './set-password.component.html',
    styleUrls: ['./set-password.component.scss']
})
export class SetPasswordComponent implements OnInit {

    private userName: string = '';
    private token: string = '';

    constructor(private authService: AuthService, private router: Router, private route: ActivatedRoute, public dialog: MatDialog) { }

    setPasswordInProgress: boolean = false;
    setPasswordFailed: boolean = false;

    loginForm = new UntypedFormGroup({
        password: new UntypedFormControl('', [Validators.required, MyValidators.hasLowercaseValidator(), MyValidators.hasUppercaseValidator(), MyValidators.hasDigitValidator(), Validators.minLength(8)]),
        password2: new UntypedFormControl('', [Validators.required, MyValidators.hasLowercaseValidator(), MyValidators.hasUppercaseValidator(), MyValidators.hasDigitValidator(), Validators.minLength(8)])
    }, MyValidators.samePasswordValidator());

    ngOnInit() {
        this.userName = this.route.snapshot.paramMap.get('userName')!;
        this.token = this.route.snapshot.paramMap.get('token')!;
        this.authService.validateSetPasswordToken(this.userName, this.token)
            .pipe(finalize(() => { this.setPasswordInProgress = false; }))
            .subscribe(
                (tokenValidated) => {
                    if (!tokenValidated) {
                        this.router.navigate(['']);
                    }
                },
                (err: Error) => {
                    this.router.navigate(['']);
                }
            );
    }

    onSubmit() {
        this.setPasswordInProgress = true;
        this.setPasswordFailed = false;
        this.authService.setPassword(this.userName, this.token, this.password?.value as string)
            .pipe(finalize(() => { this.setPasswordInProgress = false; }))
            .subscribe(
                () => {
                    this.setPasswordFailed = false;
                    const dialogRef = this.dialog.open(SetPasswordConfirmationDialog);
                    dialogRef.afterClosed().subscribe(() => { this.router.navigate(['']); });
                },
                (err: Error) => {
                    this.setPasswordFailed = true;
                    console.error(err);
                }
            );
    }

    get password() { return this.loginForm.get('password'); }

    get password2() { return this.loginForm.get('password2'); }
}

@Component({
    selector: 'set-password-confirmation-dialog',
    template: `<h1 mat-dialog-title>Udało się</h1>
                <div mat-dialog-content>Twoje hasło zostało ustawione.</div>
                <div mat-dialog-actions>
                    <button mat-raised-button mat-dialog-close>Zamknij</button>
                </div>
`
})
export class SetPasswordConfirmationDialog { }