<div class="login">
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <mat-card>
            <app-eden-logo></app-eden-logo>
            <mat-card-header>
                <div mat-card-avatar><mat-icon class="account-icon">login</mat-icon></div>
                <mat-card-title>Logowanie</mat-card-title>
                <mat-card-subtitle>Jeśli nie masz posiadasz konta wybierz REJESTRACJA</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <div class="form">
                    <mat-form-field appearance="fill">
                        <mat-label>Email / Telefon</mat-label>
                        <input matInput required formControlName="userName">
                        <mat-icon matSuffix>alternate_email</mat-icon>
                        <mat-error *ngIf="userName?.invalid">Podaj poprawny email lub telefon (9 cyfr)</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>Hasło</mat-label>
                        <input matInput type="password" required formControlName="password">
                        <mat-icon matSuffix>password</mat-icon>
                        <mat-error *ngIf="password?.invalid">Wprowadź hasło</mat-error>
                    </mat-form-field>
                    <mat-error *ngIf="authenticationFailed">Niepoprawny login/hasło lub niepotwierdzone konto</mat-error>
                    <span class="restore" routerLink="/main/reset-password">Zapomniałem hasła</span>
                </div>
            </mat-card-content>
            <mat-card-actions class="buttons">
                <button mat-raised-button color="primary" type="submit" [disabled]="!loginForm.valid || authenticationInProgress">ZALOGUJ</button>
                <button mat-raised-button routerLink="/main/register-account" [disabled]="authenticationInProgress">REJESTRACJA</button>
            </mat-card-actions>
            <mat-card-footer>
                <mat-progress-bar mode="indeterminate" *ngIf="authenticationInProgress"></mat-progress-bar>
            </mat-card-footer>
        </mat-card>
    </form>
</div>


