import { Component } from '@angular/core';

@Component({
    selector: 'app-footer',
    template: `
<footer class="footer mat-toolbar mat-primary">
    <div class="footer-list">
        <div class="footer-logo">
                <img src="../../assets/ik-logo.png" alt="Angular icon" class="docs-footer-ik-logo">
        </div>
        <div class="footer-version">
            <img src="../../assets/eden.png" alt="Angular Material icon" class="ik-logo">
        </div>
        <div class="footer-copyright">
            <div><span>Infokrak &copy;2022</span>
            <a href="https://infokrak.com.pl">&lt;eden&gt; - Zintegrowany System Zarządzania</a>
            <span>Wersja 1.0.0</span></div>
        </div>
    </div>
</footer>
  `,
    styleUrls: ['./footer.component.css']
})
export class FooterComponent {
}
