import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSliderModule } from '@angular/material/slider';
import { MatToolbarModule } from '@angular/material/toolbar';

import { MainRoutingModule } from './main-routing.module';

import { MainComponent } from './components/main/main.component';
import { LoginComponent } from './components/login/login.component';
import { MaterialComponent } from './components/material/material.component';
import { ResetPasswordComponent, ResetPasswordEmailConfirmationDialog, ResetPasswordPhoneNumberConfirmationDialog } from './components/reset-password/reset-password.component';
import { RegisterAccountComponent, RegisterEmailAccountConfirmationDialog, RegisterPhoneNumberAccountConfirmationDialog } from './components/register-account/register-account.component';
import { EdenLogoComponent } from './components/eden-logo/eden-logo.component';
import { SetPasswordComponent, SetPasswordConfirmationDialog } from './components/set-password/set-password.component';
import { ConfirmEmailComponent, ConfirmEmailConfirmationDialog } from './components/confirm-email/confirm-email.component';
import { ConfirmPhoneNumberComponent, ConfirmPhoneNumberConfirmationDialog } from './components/confirm-phone-number/confirm-phone-number.component';

@NgModule({
    declarations: [
        MainComponent,
        LoginComponent,
        MaterialComponent,
        ConfirmEmailComponent, ConfirmEmailConfirmationDialog,
        ConfirmPhoneNumberComponent, ConfirmPhoneNumberConfirmationDialog,
        ResetPasswordComponent, ResetPasswordEmailConfirmationDialog, ResetPasswordPhoneNumberConfirmationDialog,
        RegisterAccountComponent, RegisterEmailAccountConfirmationDialog, RegisterPhoneNumberAccountConfirmationDialog,
        SetPasswordComponent, SetPasswordConfirmationDialog,
        EdenLogoComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        
        MatButtonModule,
        MatCardModule,
        MatChipsModule,
        MatCheckboxModule,
        MatDialogModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatProgressBarModule,
        MatSliderModule,
        MatToolbarModule,

        MainRoutingModule,
    ],
    providers: []
})
export class MainModule { }
