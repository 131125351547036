import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class MyValidators {
    static samePasswordValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {

            const password: string = control.get("password")?.value;
            const password2: string = control.get("password2")?.value;

            if (password == "" || password2 == "") {
                return null;
            }

            if (password != password2) {
                return { 'noMatch': true }

            }
            return null;
        };
    }

    static hasDigitValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {

            const hasDigit = /\d/.test(control.value);
            return hasDigit ? null : { noDigit: true };
        };
    }

    static hasLowercaseValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {

            const hasLowercase = /[a-z]/.test(control.value);
            return hasLowercase ? null : { noLowercase: true };
        };
    }

    static hasUppercaseValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {

            const hasUppercase = /[A-Z]/.test(control.value);
            return hasUppercase ? null : { noUppercase: true };
        };
    }
}
