<div class="login">
    <form [formGroup]="confirmPhoneNumberForm" (ngSubmit)="onSubmit()">
        <mat-card>
            <app-eden-logo></app-eden-logo>
            <mat-card-header>
                <div mat-card-avatar><mat-icon class="account-icon">phone_iphone</mat-icon></div>
                <mat-card-title>Wprowadź kod SMS</mat-card-title>
                <mat-card-subtitle>Wprowadź jednorazowy kod otrzymany SMS</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <div class="form">
                    <mat-form-field appearance="fill">
                        <mat-label>Kod SMS</mat-label>
                        <input matInput required formControlName="token">
                        <mat-icon matSuffix>phone_iphone</mat-icon>
                    </mat-form-field>
                    <mat-error *ngIf="confirmPhoneNumberFailed">W tej chwili nie możemy zrealizować Twojej prośby</mat-error>
                    <mat-error *ngIf="invalidToken">Wprowadzony kod nie jest poprawny</mat-error>
                </div>
            </mat-card-content>
            <mat-card-actions>
                <button mat-raised-button type="submit" [disabled]="!confirmPhoneNumberForm.valid">ZATWIERDŹ</button>
            </mat-card-actions>
        </mat-card>
    </form>
</div>