<div class="content">
    <div class="card">
        <mat-card>
            <mat-toolbar color="primary">
                <span>Nieobecności</span>
            </mat-toolbar>
            <mat-toolbar>
                <button mat-button [matMenuTriggerFor]="menu" color="primary">
                    <span>Rok&nbsp;{{selectedYear}}</span>
                    <mat-icon>arrow_drop_down</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button *ngFor="let year of years" (click)="selectionChange(year)" mat-menu-item>
                        <span>{{year}}</span>
                    </button>
                </mat-menu>
                
            </mat-toolbar>
            <mat-card-content>
                <div class="toolcard">
                    <mat-chip-list>
                        <mat-chip color="primary" selected>Urlop wypoczynkowy</mat-chip>
                        <mat-chip color="accent" selected>Zwolnienie lekarskie</mat-chip>
                        <mat-chip>Inna nieobecność</mat-chip>
                    </mat-chip-list>
                </div>
                <div class="flex-container">
                    <div class="card card-calendar" *ngFor="let monthlyAbsence of monthlyAbsences">
                        <mat-card style="max-width: 400px;">
                            <mat-card-title>{{monthlyAbsence.monthName}}</mat-card-title>
                            <mat-card-content>
                                <mat-table [dataSource]="monthlyAbsence.weeklyAbsences" class="mat-elevation-z8">
                                    <ng-container matColumnDef="pn">
                                        <mat-header-cell *matHeaderCellDef> Pn </mat-header-cell>
                                        <mat-cell *matCellDef="let element">
                                            <div *ngIf="element.dailyAbsences[0].code?.length==1; else elseBlock">
                                                <mat-chip-list>
                                                    <mat-chip *ngIf="element.dailyAbsences[0].lp" matTooltip="{{element.dailyAbsences[0].hint}}" [class.mat-primary]="element.dailyAbsences[0].code=='U'" [class.mat-accent]="element.dailyAbsences[0].code=='C'" selected>{{element.dailyAbsences[0].lp}}</mat-chip>
                                                </mat-chip-list>
                                            </div>
                                            <ng-template #elseBlock>
                                                <mat-chip-list>
                                                    <mat-chip *ngIf="element.dailyAbsences[0].publicHoliday; else elseBlock2" color="warn" selected>{{element.dailyAbsences[0].lp}}</mat-chip>
                                                </mat-chip-list>
                                                <ng-template #elseBlock2>
                                                    {{element.dailyAbsences[0]?.lp}}
                                                </ng-template>
                                            </ng-template>
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="wt">
                                        <mat-header-cell *matHeaderCellDef> Wt </mat-header-cell>
                                        <mat-cell *matCellDef="let element">
                                            <div *ngIf="element.dailyAbsences[1].code?.length==1; else elseBlock">
                                                <mat-chip-list>
                                                    <mat-chip *ngIf="element.dailyAbsences[1].lp" matTooltip="{{element.dailyAbsences[1].hint}}" [class.mat-primary]="element.dailyAbsences[1].code=='U'" [class.mat-accent]="element.dailyAbsences[1].code=='C'" selected>{{element.dailyAbsences[1].lp}}</mat-chip>
                                                </mat-chip-list>
                                            </div>
                                            <ng-template #elseBlock>
                                                <mat-chip-list>
                                                    <mat-chip *ngIf="element.dailyAbsences[1].publicHoliday; else elseBlock2" color="warn" selected>{{element.dailyAbsences[1].lp}}</mat-chip>
                                                </mat-chip-list>
                                                <ng-template #elseBlock2>
                                                    {{element.dailyAbsences[1]?.lp}}
                                                </ng-template>
                                            </ng-template>
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="sr">
                                        <mat-header-cell *matHeaderCellDef> Śr </mat-header-cell>
                                        <mat-cell *matCellDef="let element">
                                            <div *ngIf="element.dailyAbsences[2].code?.length==1; else elseBlock">
                                                <mat-chip-list>
                                                    <mat-chip *ngIf="element.dailyAbsences[2].lp" matTooltip="{{element.dailyAbsences[2].hint}}" [class.mat-primary]="element.dailyAbsences[2].code=='U'" [class.mat-accent]="element.dailyAbsences[2].code=='C'" selected>{{element.dailyAbsences[2].lp}}</mat-chip>
                                                </mat-chip-list>
                                            </div>
                                            <ng-template #elseBlock>
                                                <mat-chip-list>
                                                    <mat-chip *ngIf="element.dailyAbsences[2].publicHoliday; else elseBlock2" color="warn" selected>{{element.dailyAbsences[2].lp}}</mat-chip>
                                                </mat-chip-list>
                                                <ng-template #elseBlock2>
                                                    {{element.dailyAbsences[2]?.lp}}
                                                </ng-template>
                                            </ng-template>
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="cz">
                                        <mat-header-cell *matHeaderCellDef> Cz </mat-header-cell>
                                        <mat-cell *matCellDef="let element">
                                            <div *ngIf="element.dailyAbsences[3].code?.length==1; else elseBlock">
                                                <mat-chip-list>
                                                    <mat-chip *ngIf="element.dailyAbsences[3].lp" matTooltip="{{element.dailyAbsences[3].hint}}" [class.mat-primary]="element.dailyAbsences[3].code=='U'" [class.mat-accent]="element.dailyAbsences[3].code=='C'" selected>{{element.dailyAbsences[3].lp}}</mat-chip>
                                                </mat-chip-list>
                                            </div>
                                            <ng-template #elseBlock>
                                                <mat-chip-list>
                                                    <mat-chip *ngIf="element.dailyAbsences[3].publicHoliday; else elseBlock2" color="warn" selected>{{element.dailyAbsences[3].lp}}</mat-chip>
                                                </mat-chip-list>
                                                <ng-template #elseBlock2>
                                                    {{element.dailyAbsences[3]?.lp}}
                                                </ng-template>
                                            </ng-template>
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="pt">
                                        <mat-header-cell *matHeaderCellDef> Pt </mat-header-cell>
                                        <mat-cell *matCellDef="let element">
                                            <div *ngIf="element.dailyAbsences[4].code?.length==1; else elseBlock">
                                                <mat-chip-list>
                                                    <mat-chip *ngIf="element.dailyAbsences[4].lp" matTooltip="{{element.dailyAbsences[4].hint}}" [class.mat-primary]="element.dailyAbsences[4].code=='U'" [class.mat-accent]="element.dailyAbsences[4].code=='C'" selected>{{element.dailyAbsences[4].lp}}</mat-chip>
                                                </mat-chip-list>
                                            </div>
                                            <ng-template #elseBlock>
                                                <mat-chip-list>
                                                    <mat-chip *ngIf="element.dailyAbsences[4].publicHoliday; else elseBlock2" color="warn" selected>{{element.dailyAbsences[4].lp}}</mat-chip>
                                                </mat-chip-list>
                                                <ng-template #elseBlock2>
                                                    {{element.dailyAbsences[4]?.lp}}
                                                </ng-template>
                                            </ng-template>
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="sb">
                                        <mat-header-cell *matHeaderCellDef> Sb </mat-header-cell>
                                        <mat-cell *matCellDef="let element">
                                            <div *ngIf="element.dailyAbsences[5].code?.length==1; else elseBlock">
                                                <mat-chip-list>
                                                    <mat-chip *ngIf="element.dailyAbsences[5].lp" matTooltip="{{element.dailyAbsences[5].hint}}" [class.mat-primary]="element.dailyAbsences[5].code=='U'" [class.mat-accent]="element.dailyAbsences[5].code=='C'" selected>{{element.dailyAbsences[5].lp}}</mat-chip>
                                                </mat-chip-list>
                                            </div>
                                            <ng-template #elseBlock>
                                                <mat-chip-list>
                                                    <mat-chip *ngIf="element.dailyAbsences[5].publicHoliday; else elseBlock2" color="warn" selected>{{element.dailyAbsences[5].lp}}</mat-chip>
                                                </mat-chip-list>
                                                <ng-template #elseBlock2>
                                                    {{element.dailyAbsences[5]?.lp}}
                                                </ng-template>
                                            </ng-template>
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="nd">
                                        <mat-header-cell *matHeaderCellDef> Nd </mat-header-cell>
                                        <mat-cell *matCellDef="let element">
                                            <div *ngIf="element.dailyAbsences[6].code?.length==1; else elseBlock">
                                                <mat-chip-list>
                                                    <mat-chip *ngIf="element.dailyAbsences[6].lp" matTooltip="{{element.dailyAbsences[6].hint}}" [class.mat-primary]="element.dailyAbsences[6].code=='U'" [class.mat-accent]="element.dailyAbsences[6].code=='C'" selected>{{element.dailyAbsences[6].lp}}</mat-chip>
                                                </mat-chip-list>
                                            </div>
                                            <ng-template #elseBlock>
                                                <mat-chip-list>
                                                    <mat-chip *ngIf="element.dailyAbsences[6].lp" color="warn" selected>{{element.dailyAbsences[6].lp}}</mat-chip>
                                                </mat-chip-list>
                                            </ng-template>
                                        </mat-cell>
                                    </ng-container>

                                    <mat-header-row *matHeaderRowDef="weekdaysColumns"></mat-header-row>
                                    <mat-row *matRowDef="let row; columns: weekdaysColumns;"></mat-row>
                                </mat-table>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>
