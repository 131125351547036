<div class="content">
    <div class="card">
        <mat-card>
            <mat-card-content>
                <mat-toolbar color="primary">
                    <span>Wnioski do akceptacji</span>
                </mat-toolbar>
                <div class="table">
                    <table mat-table style="width: 100%" *ngIf="absenceRequests" [dataSource]="absenceRequests" class="mat-elevation-z8">
                        <ng-container matColumnDef="pracownik">
                            <th mat-header-cell mat-header-cell *matHeaderCellDef> Pracownik </th>
                            <td mat-cell *matCellDef="let element"> {{element.pracownik}} </td>
                        </ng-container>
                        <ng-container matColumnDef="typ">
                            <th mat-header-cell mat-header-cell *matHeaderCellDef> Typ </th>
                            <td mat-cell *matCellDef="let element"> {{element.kaKcSlow.kuide}} - {{element.kaKcSlow.kuop2}}</td>
                        </ng-container>
                        <ng-container matColumnDef="dOd">
                            <th mat-header-cell *matHeaderCellDef> Data od </th>
                            <td mat-cell *matCellDef="let element"> {{element.dOd}} </td>
                        </ng-container>
                        <ng-container matColumnDef="dDo">
                            <th mat-header-cell *matHeaderCellDef> Data do </th>
                            <td mat-cell *matCellDef="let element"> {{element.dDo }} </td>
                        </ng-container>
                        <ng-container matColumnDef="dniRobocz">
                            <th mat-header-cell *matHeaderCellDef> Dni rob. </th>
                            <td mat-cell *matCellDef="let element"> {{element.dniRobocz }} </td>
                        </ng-container>
                        <ng-container matColumnDef="dataWniosku">
                            <th mat-header-cell *matHeaderCellDef> Data złożenia </th>
                            <td mat-cell *matCellDef="let element"> {{element.dataWniosku }} </td>
                        </ng-container>
                        <ng-container matColumnDef="uwagi">
                            <th mat-header-cell *matHeaderCellDef> Uwagi </th>
                            <td mat-cell *matCellDef="let element"> {{element.uwagi }} </td>
                        </ng-container>
                        <ng-container matColumnDef="zastepca">
                            <th mat-header-cell *matHeaderCellDef> Zastępstwo </th>
                            <td mat-cell *matCellDef="let element"> {{element.pracownikZastepstwo}} {{element.zastepstwoUwagi }} </td>
                        </ng-container>
                        <ng-container matColumnDef="cancel">
                            <th mat-header-cell *matHeaderCellDef>  </th>
                            <td mat-cell *matCellDef="let element">
                                <button mat-button [matMenuTriggerFor]="menu" color="primary">
                                    <mat-icon>approval</mat-icon>
                                    <span>Zaakceptuj</span>
                                    <mat-icon>arrow_drop_down</mat-icon>
                                </button>
                                <mat-menu #menu="matMenu">
                                    <button (click)="approve(element.id, 1)" mat-menu-item>
                                        <mat-icon>check_circle_outline</mat-icon>
                                        <span>Akceptacja</span>
                                    </button>
                                    <button (click)="approve(element.id, 3)" mat-menu-item>
                                        <mat-icon>check_circle</mat-icon>
                                        <span>Pełna akceptacja</span>
                                    </button>
                                    <button (click)="approveWithComments(element.id, 1)" mat-menu-item>
                                        <mat-icon>add_circle_outline</mat-icon>
                                        <span>Akceptacja z uwagami</span>
                                    </button>
                                    <button (click)="approveWithComments(element.id, 3)" mat-menu-item>
                                        <mat-icon>add_circle</mat-icon>
                                        <span>Pełna akceptacja z uwagami</span>
                                    </button>
                                </mat-menu>

                                <button mat-button [matMenuTriggerFor]="menu2" color="warn">
                                    <mat-icon>cancel</mat-icon>
                                    <span>Odrzuć</span>
                                    <mat-icon>arrow_drop_down</mat-icon>
                                </button>
                                <mat-menu #menu2="matMenu">
                                    <button mat-menu-item color="warn" (click)="reject(element.id)">
                                        <mat-icon>cancel</mat-icon>
                                        <span>Odrzuć</span>
                                    </button>
                                    <button mat-menu-item color="warn" (click)="rejectWithComments(element.id)">
                                        <mat-icon>cancel</mat-icon>
                                        <span>Odrzuć z uwagami</span>
                                    </button>
                                </mat-menu>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="absenceRequestsColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: absenceRequestsColumns;"></tr>
                    </table>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>
