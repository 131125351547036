import { Component } from '@angular/core';

@Component({
    selector: 'app-root',
    template: `
    <app-navbar></app-navbar>
    <div class="main-content">
        <router-outlet></router-outlet>
    </div>
    <app-footer></app-footer>
  `,
    styleUrls: ['./app.component.css']
})
export class AppComponent {
    title = '<eden>';

    constructor() {
    }
}
