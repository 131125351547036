import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-material',
    templateUrl: './material.component.html',
    styleUrls: ['./material.component.css']
})
export class MaterialComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
