<mat-card>

    <mat-card-header>
        <div mat-card-avatar><mat-icon class="account-icon">travel_explore</mat-icon></div>
        <mat-card-title>Nowy wniosek</mat-card-title>
        <mat-card-subtitle>Wniosek może być złożony w ramach jednego roku</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <div class="form" [formGroup]="newRequestForm">
            <mat-form-field appearance="fill">
                <mat-label>Wprowadź daty</mat-label>
                <mat-date-range-input [rangePicker]="picker">
                    <input matStartDate formControlName="dateFrom" placeholder="Data od" required>
                    <input matEndDate formControlName="dateTo" placeholder="Data do" required>
                </mat-date-range-input>
                <mat-hint>RRRR-MM-DD</mat-hint>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>

                <mat-error *ngIf="newRequestForm.controls.dateFrom.hasError('matStartDateInvalid')">Niepoprawna data rozpoczęcia</mat-error>
                <mat-error *ngIf="newRequestForm.controls.dateTo.hasError('matEndDateInvalid')">Niepoprawna data zakończenia</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>Rodzaj urlopu</mat-label>
                <mat-select formControlName="kuide">
                    <mat-option *ngFor="let item of kadryWnioskiTyp" [value]="item.kuide">
                        {{item.description}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-checkbox formControlName="hourly">Urlop godzinowy od do:</mat-checkbox>
            <ngx-timepicker-field *ngIf="newRequestForm.getRawValue().hourly" formControlName="hourFrom" [format]="24" [controlOnly]="true" [defaultTime]="'08:00'"></ngx-timepicker-field>
            <ngx-timepicker-field *ngIf="newRequestForm.getRawValue().hourly" formControlName="hourTo" [format]="24" [controlOnly]="true" [defaultTime]="'09:00'"></ngx-timepicker-field>

            <mat-form-field appearance="fill">
                <mat-label>Uwagi</mat-label>
                <input matInput formControlName="uwagi">
                <mat-icon matSuffix>comment</mat-icon>
                <mat-error *ngIf="newRequestForm.controls.uwagi?.invalid">Uwagi nie mogą być większe niż 50 znaków</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>Zastępca</mat-label>
                <mat-select formControlName="kaPersoZastepstwoId">
                    <mat-option *ngFor="let item of combaWnioskiUrlopowe" [value]="item.kaPersoId">
                        {{item.nazwiskoImie}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>Uwagi do zastępstwa</mat-label>
                <input matInput formControlName="zastepstwoUwagi">
                <mat-icon matSuffix>comment</mat-icon>
                <mat-error *ngIf="newRequestForm.controls.zastepstwoUwagi?.invalid">Uwagi do zastępstwa nie mogą być większe niż 50 znaków</mat-error>
            </mat-form-field>
            <mat-error *ngIf="newRequestForm?.errors?.['noYearMatch']">Wniosek musi być złożony w ramach jednego roku</mat-error>
            <mat-error *ngIf="newRequestForm?.errors?.['noDayMatch']">Wniosek godzinowy musi być złożony w ramach jednego dnia</mat-error>
        </div>

    </mat-card-content>
    <mat-card-actions class="buttons">
        <button mat-raised-button color="primary" type="submit" (click)="submitNewRequest()" mat-dialog-close [disabled]="!newRequestForm.valid">Złóż wniosek</button>
        <button mat-button (click)="onCancel()">Anuluj</button>
    </mat-card-actions>
</mat-card>
