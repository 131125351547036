<div class="flex-grow">
    <mat-sidenav-container class="flex-grow-container">
        <mat-sidenav [mode]="mode()" #drawer opened="{{shouldOpenSidenav}}">
            <mat-accordion multi>
                <mat-expansion-panel expanded>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Akta
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <mat-nav-list>
                        <mat-list-item><a (click)="itemSelected()" routerLink="personal-data" color="primary" routerLinkActive="active">Dane osobowe</a></mat-list-item>
                        <mat-list-item><a (click)="itemSelected()" routerLink="employment" routerLinkActive="active">Zatrudnienie</a></mat-list-item>
                        <mat-list-item><a (click)="itemSelected()" routerLink="absences" routerLinkActive="active">Nieobecności</a></mat-list-item>
                        <mat-list-item><a (click)="itemSelected()" routerLink="absence-requests" routerLinkActive="active">Wnioski o urlop</a></mat-list-item>
                        <mat-list-item><a (click)="itemSelected()" routerLink="absence-requests-approval" routerLinkActive="active">Wnioski do akceptacji</a></mat-list-item>
                    </mat-nav-list>
                </mat-expansion-panel>
                <mat-expansion-panel expanded>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Płace
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <mat-nav-list>
                        <mat-list-item><a (click)="itemSelected()" routerLink="payslips" routerLinkActive="active">Paski płacowe</a></mat-list-item>
                        <mat-list-item><a (click)="itemSelected()" routerLink="rmuas" routerLinkActive="active">Dokumenty RMUA</a></mat-list-item>
                        <mat-list-item><a (click)="itemSelected()" routerLink="tax-declarations" routerLinkActive="active">Deklaracje PIT</a></mat-list-item>
                    </mat-nav-list>
                </mat-expansion-panel>
            </mat-accordion>
        </mat-sidenav>
        <mat-sidenav-content>
            <router-outlet></router-outlet>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>