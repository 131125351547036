import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent, PageNotFoundComponent } from './modules/core/core.module';

const routes: Routes = [
    { path: '', component: HomeComponent, },
    { path: 'main', loadChildren: () => import('./modules/main/main.module').then(x => x.MainModule) },
    { path: 'employee', loadChildren: () => import('./modules/employee/employee.module').then(x => x.EmployeeModule) },
    { path: '**', component: PageNotFoundComponent, }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
