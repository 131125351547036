import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';

import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table'
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';

import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';

import { EmployeeRoutingModule } from './employee-routing.module';

import { AbsencesComponent } from './components/absences/absences.component';
import { AbsenceRequestsComponent } from './components/absence-requests/absence-requests.component';
import { AbsenceRequestsApprovalComponent } from './components/absence-requests-approval/absence-requests-approval.component';
import { AddAbsenceRequestApprovalCommentDialog } from './components/absence-requests-approval/add-absence-request-approval-comment-dialog.component';
import { EmployeeComponent } from './components/employee/employee.component';
import { EmploymentComponent } from './components/employment/employment.component';
import { PayslipsComponent } from './components/payslips/payslips.component';
import { RmuasComponent } from './components/rmuas/rmuas.component';
import { PersonalDataComponent } from './components/personal-data/personal-data.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { SubmitNewAbsenceRequestDialog } from './components/absence-requests/submit-new-absence-request-dialog.component';
import { TaxDeclarationsComponent } from './components/tax-declarations/tax-declarations.component';
import { WelcomeComponent } from './components/welcome/welcome.component';

import { KodPocztowyPipe } from './pipes/kod-pocztowy.pipe';

import { EmployeeService } from './services/employee.service';

import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MY_DATE_FORMATS } from './shared/my-date-formats';

@NgModule({
    declarations: [
        AbsencesComponent,
        AbsenceRequestsApprovalComponent, AddAbsenceRequestApprovalCommentDialog,
        AbsenceRequestsComponent, SubmitNewAbsenceRequestDialog,
        EmployeeComponent,
        EmploymentComponent,
        PayslipsComponent,
        RmuasComponent,
        PersonalDataComponent,
        SidenavComponent,
        TaxDeclarationsComponent,
        WelcomeComponent,

        KodPocztowyPipe
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,

        MatBadgeModule,
        MatButtonModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatDialogModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatMomentDateModule,
        MatListModule,
        MatSelectModule,
        MatSidenavModule,
        MatSnackBarModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,

        NgxMaterialTimepickerModule.setLocale('pl-PL'),

        EmployeeRoutingModule
    ],
    providers: [
        EmployeeService,
        { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true, strict: true } },
        { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }
    ]
})
export class EmployeeModule { }
