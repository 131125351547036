import { Component, OnInit } from '@angular/core';
import { EmployeeService } from '../../services/employee.service';

@Component({
    selector: 'app-tax-declarations',
    templateUrl: './tax-declarations.component.html',
    styleUrls: ['./tax-declarations.component.scss']
})
export class TaxDeclarationsComponent implements OnInit {

    constructor(private employeeService: EmployeeService) { }

    years!: bigint[];
    selectedYear!: bigint;
    taxDeclarations!: any;

    ngOnInit(): void {
        this.employeeService.taxDeclarationsYears()
            .subscribe(
                (taxDeclarationsYears) => {
                    this.selectedYear = taxDeclarationsYears[0];
                    this.years = taxDeclarationsYears;
                    this.selectionChange(this.selectedYear);
                });
    }

    selectionChange(year: bigint): void {
        this.selectedYear = year;
        this.employeeService.taxDeclarations(this.selectedYear)
            .subscribe((taxDeclarations) => {
                this.taxDeclarations = taxDeclarations;
            });
    }

    downloadTaxDeclaration(id: bigint) {
        this.employeeService.taxDeclaration(id)
            .subscribe((data) => {
                const downloadedFile = new Blob([data], { type: data.type });
                const a = document.createElement('a');
                a.href = URL.createObjectURL(downloadedFile);
                a.target = '_blank';
                a.click();
            });
    }

    taxDeclarationsColumns = ['deklaracja', 'wersja', 'rok', 'nazwisko', 'imie', 'refid', 'status', 'download'];
}
