<div class="navbar-header mat-toolbar mat-primary">
    <a mat-button *ngIf="!isAuthenticated" routerLink="">
        <mat-icon>home</mat-icon>
    </a>
    <a mat-button *ngIf="isAuthenticated && hasEmployeeRole" (click)="toggleSideNav()">
        <mat-icon>menu</mat-icon>
    </a>
    <button mat-button routerLink="/employee" *ngIf="isAuthenticated && hasEmployeeRole">
        <mat-icon>person</mat-icon>
        <span>&nbsp;e-Pracownik</span>
    </button>

    <button mat-button [matMenuTriggerFor]="menuCompany" *ngIf="isAuthenticated && hasCompanyRole">
        <mat-icon>corporate_fare</mat-icon>
        <span>&nbsp;e-Firma</span>
    </button>
    <mat-menu #menuCompany="matMenu">
        <button mat-menu-item>
            <span>Księgowość</span>
        </button>
        <button mat-menu-item>
            <span>Zakup</span>
        </button>
    </mat-menu>

    <button mat-button [matMenuTriggerFor]="menuAdministration" *ngIf="isAuthenticated && hasAdministratorRole">
        <mat-icon>admin_panel_settings</mat-icon>
        <span>&nbsp;Administracja</span>
    </button>
    <mat-menu #menuAdministration="matMenu">
        <button mat-menu-item>
            <mat-icon>supervised_user_circle</mat-icon>
            <span>Użytkownicy</span>
        </button>
    </mat-menu>

    <div class="flex-spacer"></div>

    <button mat-button [matMenuTriggerFor]="menu" *ngIf="isAuthenticated">
        <mat-icon>login</mat-icon>
        <span>&nbsp;Konto</span>
    </button>
    <mat-menu #menu="matMenu">
        <button mat-menu-item>
            <mat-icon>account_box</mat-icon>
            <span>{{userEmail}}</span>
        </button>
        <!--<button mat-menu-item>
            <mat-icon>settings</mat-icon>
            <span>Ustawienia</span>
        </button>-->
        <button mat-menu-item (click)="logout()">
            <mat-icon>logout</mat-icon>
            <span>Wyloguj</span>
        </button>
    </mat-menu>
</div>
