import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService, EventService } from '../../../shared/shared.module';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit, OnDestroy {
    private subscription!: Subscription;
    userEmail: String = '';

    constructor(private authService: AuthService, private eventService: EventService) { }

    ngOnInit(): void {
        this.subscription = this.authService.applicationUser.subscribe((x) => {
            this.userEmail = x.userName
        });
    }

    get isAuthenticated() { return this.authService.isAuthenticated(); }

    get hasEmployeeRole() { return this.authService.hasEmployeeRole(); }

    get hasCompanyRole() { return this.authService.hasCompanyRole(); }

    get hasAdministratorRole() { return this.authService.hasAdministratorRole(); }

    logout() {
        this.authService.logout();
    }

    ngOnDestroy(): void {
        this.subscription?.unsubscribe();
    }

    toggleSideNav() {
        this.eventService.toggleSidenav();
    }
}
