import { Component, OnInit } from '@angular/core';
import { EmployeeService } from '../../services/employee.service';
import { IKaPerso } from "../../models/personal-data.model";

@Component({
    selector: 'app-welcome',
    templateUrl: './welcome.component.html',
    styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {

    constructor(private employeeService: EmployeeService) { }

    personalData!: IKaPerso;

    ngOnInit(): void {
        this.employeeService.personalData()
            .subscribe(
                (personalData) => {
                    this.personalData = personalData;
                });
    }

    addrColumns: string[] = ['rodzaj', 'dOd', 'ulica', 'miejscowosc'];
}
