import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-eden-logo',
  template: `
<div class="content">
    <img src="../../assets/eden.png" alt="eden icon" class="angular-material-logo">
    <h2>
        Zintegrowany System Zarządzania
    </h2>
</div>
  `,
  styleUrls: ['./eden-logo.component.css']
})
export class EdenLogoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
