import { Component, OnInit, ViewChild } from '@angular/core';
import { SidenavComponent } from "../sidenav/sidenav.component";

@Component({
    selector: 'app-employee',
    template: `
<app-sidenav></app-sidenav>
  `,
    styleUrls: ['./employee.component.css']
})
export class EmployeeComponent implements OnInit {

    @ViewChild(SidenavComponent)
    private sidenavComponent!: SidenavComponent;

    constructor() { }

    ngOnInit(): void {
    }

    toggleDrawer() {
        this.sidenavComponent.toggleDrawer();
    }
}
