<div class="content" *ngIf="personalData">
    <div class="card">
        <mat-card class="card-wrapper">
            <mat-card-content>
                <mat-toolbar color="primary">
                    <span>Dane osobowe</span>
                </mat-toolbar>
                <mat-tab-group>
                    <mat-tab label="Dane osobowe">
                        <div class="card">
                            <mat-card class="card-personal">
                                <mat-card-content>
                                    <h5>Imię</h5> {{personalData.imie}}
                                    <h5>Drugie imię</h5> {{personalData.imie2}}
                                    <h5>Nazwisko</h5> {{personalData.nazwisko}}
                                    <h5>Data urodzenia</h5> {{personalData.dUrodzenia | date }}
                                    <h5>Miejsce urodzenia</h5> {{personalData.miejUr}}
                                    <h5>PESEL</h5> {{personalData.pesel}}
                                    <h5>Email</h5> {{personalData.emailEEden}}
                                    <h5>Telefon</h5> {{personalData.telEEden}}
                                </mat-card-content>
                            </mat-card>
                        </div>
                    </mat-tab>
                    <mat-tab label="Adresy">
                        <div class="card">
                            <mat-card>
                                <mat-card-content>
                                    <table style="width: 100%" mat-table [dataSource]="personalData.kaAdresy" class="mat-elevation-z8">
                                        <ng-container matColumnDef="rodzaj">
                                            <th mat-header-cell *matHeaderCellDef> Rodzaj </th>
                                            <td mat-cell *matCellDef="let element"> {{element.kaRodzajAdresu.opis}} </td>
                                        </ng-container>
                                        <ng-container matColumnDef="dOd">
                                            <th mat-header-cell *matHeaderCellDef> Data od </th>
                                            <td mat-cell *matCellDef="let element"> {{element.dOd | date}} </td>
                                        </ng-container>
                                        <ng-container matColumnDef="ulica">
                                            <th mat-header-cell *matHeaderCellDef> Ulica </th>
                                            <td mat-cell *matCellDef="let element"> {{element.ulica}} {{element.nrDomu}} <span *ngIf="element.nrLokalu != null">/ {{element.nrLokalu}}</span></td>
                                        </ng-container>
                                        <ng-container matColumnDef="miejscowosc">
                                            <th mat-header-cell *matHeaderCellDef> Miejscowość </th>
                                            <td mat-cell *matCellDef="let element"> {{element.kodPocztowy | kodPocztowy }} {{element.miejscowosc }} </td>
                                        </ng-container>
                                        <tr mat-header-row *matHeaderRowDef="addrColumns"></tr>
                                        <tr mat-row *matRowDef="let row; columns: addrColumns;"></tr>
                                    </table>
                                </mat-card-content>
                            </mat-card>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </mat-card-content>
        </mat-card>
    </div>
</div>
