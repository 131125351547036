import { Component, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'add-absence-request-approval-comment-dialog',
    templateUrl: './add-absence-request-approval-comment-dialog.component.html',
    styleUrls: ['./add-absence-request-approval-comment-dialog.component.scss']
})
export class AddAbsenceRequestApprovalCommentDialog {
    constructor(
        public dialogRef: MatDialogRef<AddAbsenceRequestApprovalCommentDialog>,
        @Inject(MAT_DIALOG_DATA) public data: AbsenceRequestApprovalCommentData,
    ) { }

    newRequestForm = new FormGroup({
        uwagi: new FormControl<string | null>(null, [Validators.maxLength(50)])
    });

    submitNewRequest(): void {
        this.data.uwagi = this.newRequestForm.getRawValue().uwagi;
        this.dialogRef.close({ result: true, uwagi: this.data.uwagi });
    }

    onCancel(): void {
        this.dialogRef.close({result: false});
    }

    get uwagi() { return this.newRequestForm.get('uwagi'); }
}

export interface AbsenceRequestApprovalCommentData {
    title: string;
    uwagi: string | null;
}

