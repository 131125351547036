import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EmployeeService } from '../../services/employee.service';
import { MyValidators } from '../../shared/Validators';

@Component({
    selector: 'submit-new-absence-request-dialog',
    templateUrl: './submit-new-absence-request-dialog.component.html',
    styleUrls: ['./submit-new-absence-request-dialog.component.scss']
})
export class SubmitNewAbsenceRequestDialog implements OnInit {
    constructor(private employeeService: EmployeeService,
        public dialogRef: MatDialogRef<SubmitNewAbsenceRequestDialog>,
        @Inject(MAT_DIALOG_DATA) public data: AbsenceRequestData,
    ) { }

    kadryWnioskiTyp!: any;
    combaWnioskiUrlopowe!: any;

    newRequestForm = new FormGroup({
        dateFrom: new FormControl<Date | null>(this.data.dateFrom),
        dateTo: new FormControl<Date | null>(this.data.dateTo),
        kuide: new FormControl<string | null>(null, [Validators.required]),
        hourly: new FormControl<boolean | null>(false, [Validators.required]),
        hourFrom: new FormControl<string | null>('08:00'),
        hourTo: new FormControl<string | null>('09:00'),
        uwagi: new FormControl<string | null>(null, [Validators.maxLength(50)]),
        kaPersoZastepstwoId: new FormControl<bigint | null>(null),
        zastepstwoUwagi: new FormControl<string | null>(null, [Validators.maxLength(50)])
    }, [MyValidators.sameYearValidator(), MyValidators.sameDayValidator()]);

    ngOnInit(): void {
        this.employeeService.absenceRequestsKadryWnioskiTyp(this.data.idKaUmowy)
            .subscribe(
                (response) => {
                    this.kadryWnioskiTyp = response.kadryWnioskiTyp;
                    this.combaWnioskiUrlopowe = response.combaWnioskiUrlopowe;
                    this.newRequestForm.get('kuide')?.setValue(this.kadryWnioskiTyp[0].kuide);
                });
    }

    submitNewRequest(): void {
        this.data.dateFrom = this.newRequestForm.getRawValue().dateFrom;
        this.data.dateTo = this.newRequestForm.getRawValue().dateTo;
        this.data.kuide = this.newRequestForm.getRawValue().kuide;
        this.data.hourly = this.newRequestForm.getRawValue().hourly;
        this.data.hourFrom = this.newRequestForm.getRawValue().hourFrom;
        this.data.hourTo = this.newRequestForm.getRawValue().hourTo;
        this.data.uwagi = this.newRequestForm.getRawValue().uwagi;
        this.data.kaPersoZastepstwoId = this.newRequestForm.getRawValue().kaPersoZastepstwoId;
        this.data.zastepstwoUwagi = this.newRequestForm.getRawValue().zastepstwoUwagi;
        this.dialogRef.close(this.data);
    }

    onCancel(): void {
        this.dialogRef.close();
    }

    get dateFrom() { return this.newRequestForm.get('dateFrom'); }
    get dateTo() { return this.newRequestForm.get('dateTo'); }
    get kuide() { return this.newRequestForm.get('kuide'); }
    get hourly() { return this.newRequestForm.get('hourly'); }
    get hourFrom() { return this.newRequestForm.get('hourFrom'); }
    get hourTo() { return this.newRequestForm.get('hourTo'); }
    get uwagi() { return this.newRequestForm.get('uwagi'); }
    get kaPersoZastepstwoId() { return this.newRequestForm.get('kaPersoZastepstwoId'); }
    get zastepstwoUwagi() { return this.newRequestForm.get('zastepstwoUwagi'); }
}

export interface AbsenceRequestData {
    dateFrom: Date | null;
    dateTo: Date | null;
    kuide: string | null;
    hourly: boolean | null;
    hourFrom: string | null;
    hourTo: string | null;
    uwagi: string | null;
    kaPersoZastepstwoId: bigint | null;
    zastepstwoUwagi: string | null;
    idKaUmowy: bigint;
}