import { Component, OnInit } from '@angular/core';
import { EmployeeService } from '../../services/employee.service';
import { IKaUmowy } from "../../models/employment.model";

@Component({
  selector: 'app-employment',
    templateUrl: './employment.component.html',
    styleUrls: ['./employment.component.scss']
})
export class EmploymentComponent implements OnInit {

    constructor(private employeeService: EmployeeService) { }

    kaUmowy!: IKaUmowy[];
    selectedKaUmowy!: IKaUmowy;

    ngOnInit(): void {
        this.employeeService.umowy()
            .subscribe(
                (umowy) => {
                    this.kaUmowy = umowy;
                    this.selectedKaUmowy = this.kaUmowy[0];
                });
    }

    kaUmowyColumns: string[] = ['dStart', 'dEnd', 'dzial', 'dniPodstU'];
    kaAngazeColumns: string[] = ['dAngazu', 'etatc', 'wynmc', 'stanowisko'];
}
