<div class="content">
    <div class="card">
        <mat-card>
            <mat-card-content>
                <mat-toolbar color="primary">
                    <span>Wnioski o urlop</span>
                    <button *ngIf="isMobile" mat-button (click)="newRequest()">
                        <mat-icon>add</mat-icon>
                        <span>Nowy wniosek</span>
                    </button>
                </mat-toolbar>
                <mat-toolbar>
                    <button *ngIf="!isMobile" mat-button color="primary" (click)="newRequest()">
                        <mat-icon>add</mat-icon>
                        <span>Nowy wniosek</span>
                    </button>
                    <button mat-button [matMenuTriggerFor]="menu" color="primary">
                        <span>Rok&nbsp;{{selectedYear}}</span>
                        <mat-icon>arrow_drop_down</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button *ngFor="let year of years" (click)="selectionChange(year, selectedKaUmowy)" mat-menu-item>
                            <span>{{year}}</span>
                        </button>
                    </mat-menu>
                    <button mat-button [matMenuTriggerFor]="menuU" color="primary">
                        <span>Umowa od&nbsp;{{selectedKaUmowy?.dStart}} do {{selectedKaUmowy?.dEnd}}</span>
                        <mat-icon>arrow_drop_down</mat-icon>
                    </button>
                    <mat-menu #menuU="matMenu">
                        <button *ngFor="let kaUmowa of kaUmowy" (click)="selectionChange(selectedYear, kaUmowa)" mat-menu-item>
                            <span>Umowa od&nbsp;{{kaUmowa.dStart}} do {{kaUmowa.dEnd}}</span>
                        </button>
                    </mat-menu>
                </mat-toolbar>
                <mat-card class="remaining-absence">
                    <div>
                        <span class="mat-button">Urlop do wykorzystania podstawowy:</span>
                    </div>
                    <div>
                        <span class="mat-button" color="primary" selected>{{kartotekaUrlopowaPozostalo?.dni}} dni</span>
                        <span class="mat-button" color="primary" selected>{{kartotekaUrlopowaPozostalo?.godz}} godz.</span>
                        <span class="mat-button" color="primary" selected>{{kartotekaUrlopowaPozostalo?.min}} min.</span>
                    </div>
                    <div>
                        <span class="mat-button">Urlop do wykorzystania dodatkowy:</span>
                    </div>
                    <div>
                        <span class="mat-button" color="primary" selected>{{kartotekaUrlopowaPozostalo?.dDniDod}} dni</span>
                        <span class="mat-button" color="primary" selected>{{kartotekaUrlopowaPozostalo?.godzDod}} godz.</span>
                        <span class="mat-button" color="primary" selected>{{kartotekaUrlopowaPozostalo?.minDod}} min.</span>
                    </div>
                </mat-card>
                <div class="table">
                    <table mat-table style="width: 100%" *ngIf="absenceRequests" [dataSource]="absenceRequests" class="mat-elevation-z8">
                        <ng-container matColumnDef="status">
                            <th mat-header-cell mat-header-cell *matHeaderCellDef> Status </th>
                            <td mat-cell *matCellDef="let element">
                                <mat-chip-list *ngIf="element.status==0"><mat-chip color="primary">W akceptacji</mat-chip></mat-chip-list>
                                <mat-chip-list *ngIf="element.status==1"><mat-chip color="primary" selected>Zaakceptowany</mat-chip></mat-chip-list>
                                <mat-chip-list *ngIf="element.status==2"><mat-chip color="warn" selected>Odrzucony</mat-chip></mat-chip-list>
                                <mat-chip-list *ngIf="element.status==9"><mat-chip color="accent" selected>Anulowany</mat-chip></mat-chip-list>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="typ">
                            <th mat-header-cell mat-header-cell *matHeaderCellDef> Typ </th>
                            <td mat-cell *matCellDef="let element"> {{element.kaKcSlow.kuide}} - {{element.kaKcSlow.kuop2}}</td>
                        </ng-container>
                        <ng-container matColumnDef="dOd">
                            <th mat-header-cell *matHeaderCellDef> Data od </th>
                            <td mat-cell *matCellDef="let element"> {{element.dOd}} {{element.gOd}}</td>
                        </ng-container>
                        <ng-container matColumnDef="dDo">
                            <th mat-header-cell *matHeaderCellDef> Data do </th>
                            <td mat-cell *matCellDef="let element"> {{element.dDo }} {{element.gDo}}</td>
                        </ng-container>
                        <ng-container matColumnDef="dniRobocz">
                            <th mat-header-cell *matHeaderCellDef> Dni rob. / Dni kal. </th>
                            <td mat-cell *matCellDef="let element"> {{element.dniRobocz }} / {{element.dniKalen }}</td>
                        </ng-container>
                        <ng-container matColumnDef="godzRobocz">
                            <th mat-header-cell *matHeaderCellDef> Godz. / min. kal. </th>
                            <td mat-cell *matCellDef="let element"> {{element.godzRobocz }} godz. {{element.minRobocz }} min.</td>
                        </ng-container>
                        <ng-container matColumnDef="dataWniosku">
                            <th mat-header-cell *matHeaderCellDef> Data złożenia </th>
                            <td mat-cell *matCellDef="let element"> {{element.dataWniosku }} </td>
                        </ng-container>
                        <ng-container matColumnDef="uwagi">
                            <th mat-header-cell *matHeaderCellDef> Uwagi </th>
                            <td mat-cell *matCellDef="let element"> {{element.uwagi }} </td>
                        </ng-container>
                        <ng-container matColumnDef="zastepca">
                            <th mat-header-cell *matHeaderCellDef> Zastępstwo </th>
                            <td mat-cell *matCellDef="let element"> {{element.pracownikZastepstwo}} {{element.zastepstwoUwagi }} </td>
                        </ng-container>
                        <ng-container matColumnDef="cancel">
                            <th mat-header-cell *matHeaderCellDef> </th>
                            <td mat-cell *matCellDef="let element">
                                <button mat-button *ngIf="element.status==0" color="primary" (click)="cancel(element.id)">
                                    <mat-icon>cancel</mat-icon>
                                    <span>Anuluj</span>
                                </button>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="absenceRequestsColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: absenceRequestsColumns;"></tr>
                    </table>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>
