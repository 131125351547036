import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../shared/shared.module';

@Component({
    selector: 'app-home',
    template: `
    <p>
      home works!
    </p>
  `,
    styles: [
    ]
})
export class HomeComponent implements OnInit {

    constructor(private authService: AuthService, private router: Router) { }

    ngOnInit(): void {
        if (this.authService.isAuthenticated()) {
            this.router.navigate(['/employee'])
        } else {
            this.router.navigate(['/main']);
        }
    }

}
