import { Component, OnInit } from '@angular/core';
import { EmployeeService } from '../../services/employee.service';

@Component({
  selector: 'app-absences',
    templateUrl: './absences.component.html',
    styleUrls: ['./absences.component.scss']
})
export class AbsencesComponent implements OnInit {

    constructor(private employeeService: EmployeeService) { }

    years!: bigint[];
    selectedYear!: bigint;
    monthlyAbsences!: any;

    ngOnInit(): void {
        this.employeeService.absencesYears()
            .subscribe(
                (absencesYears) => {
                    this.selectedYear = absencesYears[0];
                    this.years = absencesYears;
                    this.selectionChange(this.selectedYear);
                });
    }

    selectionChange(year: bigint): void {
        this.selectedYear = year;
        this.employeeService.absences(this.selectedYear)
            .subscribe((x) => {
                this.monthlyAbsences = x.monthlyAbsences;
            });
    }

    weekdaysColumns = ['pn', 'wt', 'sr', 'cz', 'pt', 'sb', 'nd'];
}
