import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { MainComponent } from './components/main/main.component';
import { LoginComponent } from './components/login/login.component';
import { ConfirmEmailComponent } from './components/confirm-email/confirm-email.component';
import { ConfirmPhoneNumberComponent } from './components/confirm-phone-number/confirm-phone-number.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { RegisterAccountComponent } from './components/register-account/register-account.component';
import { SetPasswordComponent } from './components/set-password/set-password.component';
import { MaterialComponent } from './components/material/material.component';

const routes: Routes = [
    { path: '', redirectTo: 'login', pathMatch: 'full' },
    {
        path: '', component: MainComponent, children: [
            { path: 'login', component: LoginComponent },
            { path: 'reset-password', component: ResetPasswordComponent },
            { path: 'register-account', component: RegisterAccountComponent },
            { path: 'set-password/:userName/:token', component: SetPasswordComponent },
            { path: 'confirm-email/:email/:token', component: ConfirmEmailComponent },
            { path: 'confirm-phone-number/:phoneNumber', component: ConfirmPhoneNumberComponent },
            { path: 'material', component: MaterialComponent }
        ]
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class MainRoutingModule { }
