import { Component, OnInit } from '@angular/core';
import { EmployeeService } from '../../services/employee.service';

@Component({
    selector: 'app-rmuas',
    templateUrl: './rmuas.component.html',
    styleUrls: ['./rmuas.component.scss']
})
export class RmuasComponent implements OnInit {

    constructor(private employeeService: EmployeeService) { }

    years!: bigint[];
    selectedYear!: bigint;
    rmuas!: any;

    ngOnInit(): void {
        this.employeeService.rmuasYears()
            .subscribe(
                (rmuasYears) => {
                    this.selectedYear = rmuasYears[0];
                    this.years = rmuasYears;
                    this.selectionChange(this.selectedYear);
                });
    }

    selectionChange(year: bigint): void {
        this.selectedYear = year;
        this.employeeService.rmuas(this.selectedYear)
            .subscribe((rmuas) => {
                this.rmuas = rmuas;
            });
    }

    downloadRmua(id: bigint) {
        this.employeeService.rmua(id)
            .subscribe((data) => {
                const downloadedFile = new Blob([data], { type: data.type });
                const a = document.createElement('a');
                a.href = URL.createObjectURL(downloadedFile);
                a.target = '_blank';
                a.click();
            });
    }

    rmuasColumns = ['rok', 'mc', 'nrListy', 'nazwaListy', 'download'];

}
