import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class EventService {
    public toggleSidenav$: EventEmitter<void> = new EventEmitter();

    toggleSidenav() {
        this.toggleSidenav$.emit();
    }
}