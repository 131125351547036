<div class="content" *ngIf="kaUmowy">
    <div class="card">
        <mat-card>
            <mat-toolbar color="primary">
                <span>Umowy</span>
            </mat-toolbar>
            <mat-card-content>
                <div class="table">
                    <table style="width: 100%" mat-table [dataSource]="kaUmowy" class="mat-elevation-z8">
                        <ng-container matColumnDef="dStart">
                            <th mat-header-cell *matHeaderCellDef> Data od </th>
                            <td mat-cell *matCellDef="let element"> {{element.dStart | date}} </td>
                        </ng-container>
                        <ng-container matColumnDef="dEnd">
                            <th mat-header-cell *matHeaderCellDef> Data do </th>
                            <td mat-cell *matCellDef="let element"> {{element.dEnd | date}} </td>
                        </ng-container>
                        <ng-container matColumnDef="dzial">
                            <th mat-header-cell *matHeaderCellDef> Dział </th>
                            <td mat-cell *matCellDef="let element"> {{element.dzial}} </td>
                        </ng-container>
                        <ng-container matColumnDef="dniPodstU">
                            <th mat-header-cell *matHeaderCellDef> Podstawa urlopu </th>
                            <td mat-cell *matCellDef="let element"> {{element.dniPodstU }} </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="kaUmowyColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: kaUmowyColumns;" class="element-row" [class.selected-row]="row == selectedKaUmowy" (click)="selectedKaUmowy = row"></tr>
                    </table>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="card" *ngIf="selectedKaUmowy">
        <mat-card>
            <mat-toolbar color="primary">
                <span>Angaże</span>
            </mat-toolbar>
            <mat-card-content>
                <div class="table">
                    <table style="width: 100%" mat-table [dataSource]="selectedKaUmowy.kaAngaze" class="mat-elevation-z8">
                        <ng-container matColumnDef="dAngazu">
                            <th mat-header-cell *matHeaderCellDef> Data angażu </th>
                            <td mat-cell *matCellDef="let element"> {{element.dAngazu | date}} </td>
                        </ng-container>
                        <ng-container matColumnDef="etatc">
                            <th mat-header-cell *matHeaderCellDef> Wymiar etatu </th>
                            <td mat-cell *matCellDef="let element"> {{element.etatc}} </td>
                        </ng-container>
                        <ng-container matColumnDef="wynmc">
                            <th mat-header-cell *matHeaderCellDef> Wynagrodzenie </th>
                            <td mat-cell *matCellDef="let element"> {{element.wynmc | currency:'PLN'}} </td>
                        </ng-container>
                        <ng-container matColumnDef="stanowisko">
                            <th mat-header-cell *matHeaderCellDef> Stanowisko </th>
                            <td mat-cell *matCellDef="let element"> {{element.kaStanowiska.nazwa }} </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="kaAngazeColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: kaAngazeColumns;"></tr>
                    </table>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>
