import { Component } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '../../../shared/shared.module';
import { finalize } from 'rxjs/operators';
import { MyValidators } from '../shared/Validators';

@Component({
    selector: 'app-register-account',
    templateUrl: './register-account.component.html',
    styleUrls: ['./register-account.component.scss']
})
export class RegisterAccountComponent {

    registerAccountFailed: boolean = false;
    registerAccountInProgress: boolean = false;

    loginForm = new UntypedFormGroup({
        userName: new UntypedFormControl('', [Validators.required, Validators.pattern('^(([a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4})|(\\d{9}))$')]),
        nrprac: new UntypedFormControl('', [Validators.required]),
        password: new UntypedFormControl('', [Validators.required, MyValidators.hasLowercaseValidator(), MyValidators.hasUppercaseValidator(), MyValidators.hasDigitValidator(), Validators.minLength(8)]),
        password2: new UntypedFormControl('', [Validators.required, MyValidators.hasLowercaseValidator(), MyValidators.hasUppercaseValidator(), MyValidators.hasDigitValidator(), Validators.minLength(8)]),
        disclaimer: new FormControl<boolean | null>(false, Validators.requiredTrue)
    }, MyValidators.samePasswordValidator());

    constructor(private authService: AuthService, private router: Router, public dialog: MatDialog) { }

    onSubmit() {
        this.registerAccountFailed = false;
        this.registerAccountInProgress = true;
        const userName: string = this.userName?.value;
        this.authService.registerAccount(userName, this.nrprac?.value, this.password?.value)
            .pipe(finalize(() => { this.registerAccountInProgress = false; }))
            .subscribe(
                () => {
                    const isEmail = userName.includes('@');
                    if (isEmail) {
                        const dialogRef = this.dialog.open(RegisterEmailAccountConfirmationDialog);
                        dialogRef.afterClosed().subscribe(() => { this.router.navigate(['']); });
                    } else {
                        const dialogRef = this.dialog.open(RegisterPhoneNumberAccountConfirmationDialog);
                        dialogRef.afterClosed().subscribe(() => { this.router.navigate(['/main/confirm-phone-number/' + userName]); });
                    }
                },
                (err: Error) => {
                    this.registerAccountFailed = true;
                    console.error(err);
                }
            );
    }

    get userName() { return this.loginForm.get('userName'); }

    get nrprac() { return this.loginForm.get('nrprac'); }

    get password() { return this.loginForm.get('password'); }

    get password2() { return this.loginForm.get('password2'); }

    get disclaimer() { return this.loginForm.get('disclaimer'); }
}

@Component({
    selector: 'register-email-account-confirmation-dialog',
    template: `<h1 mat-dialog-title>Udało się - sprawdź pocztę.</h1>
                <div mat-dialog-content>Jeśli podałeś poprawne dane, otrzymasz link do aktywacji konta.</div>
                <div mat-dialog-actions>
                    <button mat-raised-button mat-dialog-close>Zamknij</button>
                </div>
`
})
export class RegisterEmailAccountConfirmationDialog { }

@Component({
    selector: 'register-phone-number-account-confirmation-dialog',
    template: `<h1 mat-dialog-title>Udało się - sprawdź telefon.</h1>
                <div mat-dialog-content>Jeśli podałeś poprawne dane, otrzymasz kod do aktywacji konta.</div>
                <div mat-dialog-actions>
                    <button mat-raised-button mat-dialog-close>Zamknij</button>
                </div>
`
})
export class RegisterPhoneNumberAccountConfirmationDialog { }