import { Component, OnInit } from '@angular/core';
import { EmployeeService } from '../../services/employee.service';

@Component({
    selector: 'app-payslips',
    templateUrl: './payslips.component.html',
    styleUrls: ['./payslips.component.scss']
})
export class PayslipsComponent implements OnInit {

    constructor(private employeeService: EmployeeService) { }

    years!: bigint[];
    selectedYear!: bigint;
    payslips!: any;

    ngOnInit(): void {
        this.employeeService.payslipsYears()
            .subscribe(
                (payslipsYears) => {
                    this.selectedYear = payslipsYears[0];
                    this.years = payslipsYears;
                    this.selectionChange(this.selectedYear);
                });
    }

    selectionChange(year: bigint): void {
        this.selectedYear = year;
        this.employeeService.payslips(this.selectedYear)
            .subscribe((payslips) => {
                this.payslips = payslips;
            });
    }

    downloadPayslip(id: bigint) {
        this.employeeService.payslip(id)
            .subscribe((data) => {
                const downloadedFile = new Blob([data], { type: data.type });
                const a = document.createElement('a');
                a.href = URL.createObjectURL(downloadedFile);
                a.target = '_blank';
                a.click();
            });
    }

    payslipsColumns = ['rok', 'mc', 'nrListy', 'nazwaListy', 'download'];

}
