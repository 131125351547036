import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, Subscription, throwError, of, Subject, BehaviorSubject } from 'rxjs';
import { catchError, map, tap, delay, finalize } from 'rxjs/operators';
import { IAuthModel, IApplicationUser } from "../models/auth.model";

@Injectable({
    providedIn: 'root',
})
export class AuthService implements OnDestroy {
    private readonly loginUrl = '/api/v1/auth/login';
    private readonly logoutUrl = '/api/v1/auth/logout';
    private readonly refreshTokenUrl = '/api/v1/auth/refresh-token';
    private readonly resetPasswordUrl = '/api/v1/auth/reset-password';
    private readonly registerAccountUrl = '/api/v1/auth/register-account';
    private readonly setPasswordUrl = '/api/v1/auth/set-password';
    private readonly validateSetPasswordTokenUrl = '/api/v1/auth/validate-set-password-token';
    private readonly validateEmailConfirmationTokenUrl = '/api/v1/auth/validate-email-confirmation-token';
    private readonly validatePhoneNumberConfirmationTokenUrl = '/api/v1/auth/validate-phone-number-confirmation-token';

    private timer!: Subscription;

    private _applicationUser = new BehaviorSubject<IApplicationUser>({} as IApplicationUser);
    applicationUser: Subject<IApplicationUser> = this._applicationUser;

    constructor(private http: HttpClient, private router: Router) {
        window.addEventListener('storage', this.storageEventListener.bind(this));
        if (this.isAuthenticated()) {
            this.startTokenTimer();
            this._applicationUser.next({
                userName: localStorage.getItem('user-name') ?? '',
            });
        }
    }

    ngOnDestroy(): void {
        window.removeEventListener('storage', this.storageEventListener.bind(this));
    }

    login(userName: string, password: string): Observable<IAuthModel> {
        return this.http.post<IAuthModel>(this.loginUrl, { userName, password })
            .pipe(
                catchError(this.handleError),
                tap((x) => {
                    this._applicationUser.next({ userName: x.userName });
                    this.setLocalStorage(x);
                    this.startTokenTimer();
                }));
    }

    logout() {
        this.http.post(this.logoutUrl, {}).pipe(finalize(() => {
            this.stopTokenTimer();
            this.clearLocalStorage();
            this._applicationUser.next({} as IApplicationUser);
            this.router.navigate(['']);

        })).subscribe();
    }

    isAuthenticated() {
        return this.getTokenRemainingTime() > 0;
    }

    resetPassword(userName: string): Observable<any> {
        return this.http.post(this.resetPasswordUrl, { userName })
            .pipe(catchError(this.handleError));
    }

    registerAccount(userName: string, nrprac: string, password: string): Observable<any> {
        return this.http.post(this.registerAccountUrl, { userName, nrprac, password })
            .pipe(catchError(this.handleError));
    }

    setPassword(userName: string, token: string, password: string): Observable<any> {
        return this.http.post(this.setPasswordUrl, { userName, token, password })
            .pipe(catchError(this.handleError));
    }

    validateSetPasswordToken(userName: string, token: string): Observable<boolean> {
        return this.http.post<boolean>(this.validateSetPasswordTokenUrl, { userName, token })
            .pipe(catchError(this.handleError));
    }

    validateEmailConfirmationToken(email: string, token: string): Observable<boolean> {
        return this.http.post<boolean>(this.validateEmailConfirmationTokenUrl, { email, token })
            .pipe(catchError(this.handleError));
    }

    validatePhoneNumberConfirmationToken(phoneNumber: string, token: string): Observable<boolean> {
        return this.http.post<boolean>(this.validatePhoneNumberConfirmationTokenUrl, { phoneNumber, token })
            .pipe(catchError(this.handleError));
    }

    refreshToken(): Observable<any> {
        var now = Date.now();
        let lastAccessString: string = localStorage.getItem('last-access') ?? '';
        var lastAccessDate = Number(lastAccessString);
        if (now - lastAccessDate > 5 * 60 * 1000) {
            this.logout();
            return of(null);
        }
        const refreshToken = localStorage.getItem('refresh-token');
        if (!refreshToken) {
            this.clearLocalStorage();
            return of(null);
        }

        let httpOptions = {
            headers: new HttpHeaders({
                Authorization: 'Bearer ' + localStorage.getItem('access-token') //todo move to interceptor
            })
        };

        return this.http
            .post<IAuthModel>(this.refreshTokenUrl, { refreshToken }, httpOptions)
            .pipe(
                map((x) => {
                    this.setLocalStorage(x);
                    this.startTokenTimer();
                    this._applicationUser.next({ userName: x.userName });
                    return x;
                })
            );
    }

    setLocalStorage(authModel: IAuthModel) {
        localStorage.setItem('user-name', authModel.userName);
        localStorage.setItem('access-token', authModel.accessToken);
        localStorage.setItem('refresh-token', authModel.refreshToken);
        localStorage.setItem('login-event', 'login' + Math.random());
    }

    clearLocalStorage() {
        localStorage.removeItem('user-name');
        localStorage.removeItem('access-token');
        localStorage.removeItem('refresh-token');
        localStorage.setItem('logout-event', 'logout' + Math.random());
    }

    hasEmployeeRole(): boolean {
        return this.hasRole(EdenRole.Employee);
    }

    hasCompanyRole(): boolean {
        return this.hasRole(EdenRole.Company);
    }

    hasAdministratorRole(): boolean {
        return this.hasRole(EdenRole.Administrator);
    }

    private hasRole(roleName: string): boolean {
        let roles = this.getRoles();
        return roles == roleName || roles.includes(roleName);
    }

    private getRoles(): string|string[] {
        const accessToken = localStorage.getItem('access-token');
        if (!accessToken) {
            return [];
        }
        const jwtToken = JSON.parse(atob(accessToken.split('.')[1]));
        const roles = jwtToken["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
        return roles;
    }

    private getTokenRemainingTime(): number {
        const accessToken = localStorage.getItem('access-token');
        if (!accessToken) {
            return 0;
        }
        const jwtToken = JSON.parse(atob(accessToken.split('.')[1]));
        const expires = new Date(jwtToken.exp * 1000);
        return expires.getTime() - Date.now();
    }

    private startTokenTimer() {
        const timeout = this.getTokenRemainingTime();
        this.timer = of(true)
            .pipe(
                delay(timeout-(60*1000)),
                tap(() => this.refreshToken().subscribe())
            )
            .subscribe();
    }

    private stopTokenTimer() {
        this.timer?.unsubscribe();
    }

    private storageEventListener(event: StorageEvent) {
        if (event.storageArea === localStorage) {
            if (event.key === 'logout-event') {
                this._applicationUser.next({} as IApplicationUser);
                this.router.navigate(['']);
            }
            if (event.key === 'login-event') {
                this._applicationUser.next({
                    userName: localStorage.getItem('user-name') ?? ''
                });
                this.router.navigate(['']);
            }
        }
    }

    private handleError(error: HttpErrorResponse) {
        if (error.status === 0) {
            console.error('An error occurred:', error.error);
        } else {
            console.error(
                `Backend returned code ${error.status}, body was: `, error.error);
        }
        return throwError(() => new Error('Request error'));
    }
}

enum EdenRole {
    Employee = "Employee",
    Company = "Company",
    Administrator = "Administrator"
}
