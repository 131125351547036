<div class="login">
    <form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">
        <mat-card>
            <app-eden-logo></app-eden-logo>
            <mat-card-header>
                <div mat-card-avatar><mat-icon class="account-icon">lock_reset</mat-icon></div>
                <mat-card-title>Reset hasła</mat-card-title>
                <mat-card-subtitle>Jeśli nie pamiętasz hasła wprowadź email podany podczas rejestracji</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <div class="form">
                    <mat-form-field appearance="fill">
                        <mat-label>Email / Telefon</mat-label>
                        <input matInput required formControlName="userName">
                        <mat-icon matSuffix>alternate_email</mat-icon>
                        <mat-error *ngIf="userName?.invalid">Podaj poprawny email lub telefon (9 cyfr)</mat-error>
                    </mat-form-field>
                    <mat-error *ngIf="resetPasswordFailed">W tej chwili nie możemy zrealizować Twojej prośby</mat-error>
                </div>
            </mat-card-content>
            <mat-card-actions>
                <button mat-raised-button type="submit" [disabled]="!resetPasswordForm.valid">RESETUJ HASŁO</button>
            </mat-card-actions>
        </mat-card>
    </form>
</div>


