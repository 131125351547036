<div class="content" *ngIf="personalData">
    <div class="card">
        <mat-card class="card-wrapper">
            <mat-card-content>
                <mat-toolbar color="primary">
                    <span>Portal Obsługi Pracownika &lt;e-eden&gt;</span>
                </mat-toolbar>
                <div class="card">
                    <mat-card class="card-personal">
                        <mat-card-content>
                            <h3>Dzień dobry, {{personalData.imie}}</h3>
                            <div>
                                Witaj w Portalu Obsługi Pracownika, wybierz interesującą Cię pozycje w menu
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>
