import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class MyValidators {
    static sameYearValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {

            const dateFrom: Date = new Date(control.get("dateFrom")?.value);
            const dateTo: Date = new Date(control.get("dateTo")?.value);

            if (dateFrom == null || dateTo == null || control.get("dateFrom")?.value == null || control.get("dateTo")?.value == null) {
                return null;
            }

            if (dateFrom.getFullYear() != dateTo.getFullYear()) {
                return { 'noYearMatch': true }

            }
            return null;
        };
    }

    static sameDayValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {

            const hourly: boolean = control.get("hourly")?.value;
            const dateFrom: Date = new Date(control.get("dateFrom")?.value);
            const dateTo: Date = new Date(control.get("dateTo")?.value);

            if (!hourly) {
                return null;
            }

            if (dateFrom == null || dateTo == null || control.get("dateFrom")?.value == null || control.get("dateTo")?.value == null) {
                return null;
            }

            if (dateFrom.getFullYear() != dateTo.getFullYear() || dateFrom.getMonth() != dateTo.getMonth() || dateFrom.getDay() != dateTo.getDay()) {
                return { 'noDayMatch': true }

            }
            return null;
        };
    }
}
