import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '../../../shared/shared.module';
import { Router, ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'app-confirm-email',
    templateUrl: './confirm-email.component.html',
    styleUrls: ['./confirm-email.component.css']
})
export class ConfirmEmailComponent implements OnInit {

    private email: string = '';
    private token: string = '';

    constructor(private authService: AuthService, private router: Router, private route: ActivatedRoute, public dialog: MatDialog) { }

    ngOnInit() {
        this.email = this.route.snapshot.paramMap.get('email')!;
        this.token = this.route.snapshot.paramMap.get('token')!;
        this.authService.validateEmailConfirmationToken(this.email, this.token)
            .pipe(finalize(() => { this.router.navigate(['']); }))
            .subscribe(
                (tokenValidated) => {
                    if (tokenValidated) {
                        const dialogRef = this.dialog.open(ConfirmEmailConfirmationDialog);
                        dialogRef.afterClosed().subscribe(() => { this.router.navigate(['']); });
                    }
                },
                (err: Error) => {
                }
            );
    }
}

@Component({
    selector: 'email-confirmed-confirmation-dialog',
    template: `<h1 mat-dialog-title>Udało się</h1>
                <div mat-dialog-content>Twój email został potwierdzony.</div>
                <div mat-dialog-actions>
                    <button mat-raised-button mat-dialog-close>Zamknij</button>
                </div>
`
})
export class ConfirmEmailConfirmationDialog { }